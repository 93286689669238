import React, { Component, Fragment } from 'react';
import { Row, Col, Button, InputGroup, FormControl } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHistory, faSearch } from '@fortawesome/free-solid-svg-icons';
import { formatDateTime, getInvoiceStatusClass, getInvoiceStatusText } from '../../Utilities';
import PagingTable from '../common/tables/PagingTable';
import LoadingBar from '../common/LoadingBar';
import { WarningAlert, ErrorAlert } from '../common/Alerts';
import { GET } from '../../Consumer';
import Endpoints from '../common/Endpoints';

import './Invoices.scss';

const HEADERS = ["Invoice Reference", "Date Created", "Date Charged", "Payment Type", "Payment Reference", "Number of Returns", "Total (£)"];

const ViewInvoice = ({ clickEvent, invoiceReference }) => {
    return <Button variant="link" className="m-0 underline btn btn-link" onClick={clickEvent}>{invoiceReference}</Button>;
};

const NoInvoices = () => (
    <div className="message-empty">
        <FontAwesomeIcon icon={faHistory} size="8x" />
        <div className="message">
            <p>No invoices have been created yet. When an invoice has been created you can find the details here.</p>
        </div>
    </div>
);

const NoInvoicesSearch = () => (
    <Row>
        <Col sm={12} md={6}>
            <WarningAlert message="There are no results matching your search, check that you entered the details correctly and try again." />
        </Col>
    </Row>
);

const DownloadError = () => (
    <Row>
        <Col sm={12} md={6}>
            <ErrorAlert messages="There is an issue with downloading your invoice please try again later." />
        </Col>
    </Row>
);

const InvoicesFilter = ({ filter, sort, onFilterChange, onSearch, onClear }) => (
    <React.Fragment>
        <Row className="form-group-filter mt-3">
            <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 4 }}>
                <InputGroup>
                    <FormControl id="invoiceReferenceField" type="text" name="invoiceReference" placeholder="Search by invoice reference..." value={filter.invoiceReference} onChange={onFilterChange} />
                    <InputGroup.Append>
                        <InputGroup.Text id="searchBox" className="search-icon">
                            <FontAwesomeIcon icon={faSearch} />
                        </InputGroup.Text>
                    </InputGroup.Append>
                </InputGroup>
            </Col>
            <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 2 }}>
                <FormControl id="dateFromField" type="date" className="filter-date" name="dateFrom" maxLength="50" value={filter.dateFrom} onChange={onFilterChange} />
            </Col>
            <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 2 }}>
                <FormControl id="dateToField" type="date" className="filter-date" name="dateTo" maxLength="50" value={filter.dateTo} onChange={onFilterChange} />
            </Col>
            <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 4 }}>
                <FormControl as="select" name="sort" value={sort} onChange={onFilterChange}>
                    <option value="DateCreatedDesc">Newest to oldest</option>
                    <option value="DateCreatedAsc">Oldest to newest</option>
                </FormControl>
            </Col>
        </Row>
        <div className="button-group-filter mb-3 d-none d-md-block">
            <Button variant="primary" className="float-right ml-3" onClick={onSearch}>Apply Filters</Button>
            <Button variant="secondary" className="float-right" onClick={onClear}>Clear Filters</Button>
        </div>
        <Row className="mb-3 d-md-none d-lg-none d-xl-none">
            <Col xs={12} sm={12}>
                <Button variant="primary" className="mb-0" onClick={onSearch}>Apply Filters</Button>
            </Col>
            <Col xs={12} sm={12}>
                <Button variant="secondary" onClick={onClear}>Clear Filters</Button>
            </Col>
        </Row>
    </React.Fragment>
);

class Invoices extends Component {

    state = {
        loading: true,
        pageIndex: 1,
        pageCount: 2,
        invoices: [],
        filter: {
            invoiceReference: "",
            dateFrom: "",
            dateTo: "",
            dateCreatedDescending: true
        },
        sort: "DateCreatedDesc",
        firstRender: true,
        downloadError: false
    };


    async componentDidMount() {
        await this.getInvoices();
    }

    getInvoices = () => {
        const { pageIndex } = this.state;
        const filter = { ...this.state.filter };
        let url = new URL(Endpoints.INVOICE.GET.INVOICES);

        Object.keys(filter).forEach(k => url.searchParams.append(k, filter[k]));
        url.searchParams.append("pageIndex", pageIndex);

        return GET(url)
            .catch(error => console.log(error))
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(results => {
                const invoices = results ? results.data.invoices : [];
                const pageCount = results ? results.data.pageCount : 0;
                this.setState({ invoices: invoices, pageCount: pageCount, loading: false });
            });
    }

    downloadInvoice = (id) => {
        this.setState({ loading: true });
        return GET(Endpoints.INVOICE.GET.DOWNLOAD + id)
            .then(response => response.blob())
            .then(invoiceBlob => {
                var objectURL = URL.createObjectURL(invoiceBlob);
                window.open(objectURL);
                this.setState({ downloadError: false, loading: false });
            }).catch(() => this.setState({ downloadError: true, loading: false }));
    }

    getInvoiceRows = () => {
        const { invoices } = this.state;
        const invoiceRows = [];
        invoices.forEach(invoice => invoiceRows.push([<ViewInvoice invoiceReference={invoice.invoiceReference} clickEvent={() => this.downloadInvoice(invoice.id)} />, formatDateTime(invoice.createdDate), invoice.chargeSuccessful != null  ? formatDateTime(invoice.chargeSuccessful) : '', <span className={getInvoiceStatusClass(invoice.paymentType)}>{getInvoiceStatusText(invoice.paymentType)}</span>, invoice.paymentReference, invoice.returnsTotal, invoice.finalTotal.toFixed(2)]));
        return invoiceRows;
    }

    onNext = async () => {
        const { pageIndex, pageCount } = this.state;
        if (pageIndex < pageCount) {
            this.setState({ pageIndex: parseInt(pageIndex) + 1, loading: true, firstRender: false }, this.getInvoices);
        }
    }

    onEnd = async () => {
        const { pageIndex, pageCount } = this.state;
        if (pageIndex < pageCount) {
            this.setState({ pageIndex: pageCount, loading: true, firstRender: false }, this.getInvoices);
        }
    }

    onPrevious = async () => {
        const { pageIndex } = this.state;
        if (pageIndex > 1) {
            this.setState({ pageIndex: parseInt(pageIndex) - 1, loading: true, firstRender: false }, this.getInvoices);
        }
    }

    onStart = async () => {
        const { pageIndex } = this.state;
        if (pageIndex > 1) {
            this.setState({ pageIndex: 1, loading: true, firstRender: false }, this.getInvoices);
        }
    }

    onClear = async (e) => {
        e.preventDefault();
        this.setState({
            filter: { paymentReference: "", dateFrom: "", dateTo: "", dateCreatedDescending: true, firstRender: false }
        }, this.getInvoices);
    }

    onSearch = async (e) => {
        e.preventDefault();
        this.setState({ loading: true, firstRender: false });
        await this.getInvoices();
    }

    onFilterChange = (e) => {
        const { name, value } = e.target;

        if (name === "sort") {
            this.setState({ sort: value });

            if (value === "DateCreatedAsc") {
                this.setState(prevState => ({
                    filter: {
                        ...prevState.filter,
                        dateCreatedDescending: false
                    }
                }));
            } else {
                this.setState(prevState => ({
                    filter: {
                        ...prevState.filter,
                        dateCreatedDescending: true
                    }
                }));
            }
        } else {
            this.setState(prevState => ({
                filter: {
                    ...prevState.filter,
                    ...{ [name]: value }
                }
            }));
        }
    }

    render() {

        const { loading, invoices, firstRender, downloadError, filter, sort, pageIndex, pageCount } = this.state;
        const noInvoices = invoices.length === 0;

        return (
            <div className="main-content">
                {loading && <LoadingBar />}
                <h3>Invoicing</h3>
                <h4>Invoices History</h4>
                {downloadError && <DownloadError />}
                {(noInvoices && firstRender)
                    ? <NoInvoices />
                    : <Fragment>
                        <InvoicesFilter filter={filter} sort={sort} onFilterChange={this.onFilterChange} onSearch={this.onSearch} onClear={this.onClear} />
                        {noInvoices && <NoInvoicesSearch />}
                        <PagingTable headers={HEADERS} rows={this.getInvoiceRows()} onNext={this.onNext} onPrevious={this.onPrevious} onStart={this.onStart} onEnd={this.onEnd} pageIndex={pageIndex} pageCount={pageCount} />
                    </Fragment>}
            </div>
        );
    }
}

export default Invoices;