const USER_API = process.env.REACT_APP_USER_API;
const RETAILER_API = process.env.REACT_APP_RETAILER_API;

const Endpoints = {
    ALL_ADDRESS_TYPES: USER_API + '/api/addresstype/getalladdresstypes',

    USER: {
        POST: {
            REGULAR_REGISTRATION: USER_API + '/api/user/register',
            VERIFY_MOBILE_NUMBER: USER_API + '/api/user/verifymobilenumber',
            VERIFYCODE: USER_API + '/api/user/verifycode',
            CANCELREGISTRATION: USER_API + '/api/user/cancelregistration',
            REGULAR_LOGIN: USER_API + '/api/user/login',
        },
        GET: {
            GOOGLE_LOGIN: USER_API + '/api/user/googlelogin',
            RESENDVERIFICATIONCODE: USER_API + '/api/user/resendverificationcode'
        }
    },
    PASSWORD: {
        POST: {
            RESET_REQUEST: USER_API + '/api/passwordresetrequest/resetpasswordrequest',
            RETAILER_RESET_REQUEST: USER_API + '/api/passwordresetrequest/resetretailerpasswordrequest'
        },
        PUT: {
            CHANGEPASSWORD: USER_API + '/api/passwordresetrequest/changepassword'
        },
        GET: {
            VERIFY_RESET_REQUEST: USER_API + '/api/passwordresetrequest/verifylinkvalidity'
        }
    },
    ADDRESS: {
        POST: {
            CREATE_ADDRESS: USER_API + '/api/useraddress/createaddress',
        },
        GET: {
            ADDRESSES_BY_USERID: USER_API + '/api/useraddress/getbyuserid/',
            ADDRESS_GETBYID: USER_API + '/api/useraddress/getbyaddressid/'
        },
        PUT: {
            ADDRESS_SET_DEFAULT: USER_API + '/api/useraddress/setdefault/',
            EDIT_ADDRESS: USER_API + '/api/useraddress/editaddress',
            REMOVE_ADDRESS: USER_API + '/api/useraddress/removeaddress'
        }
    },
    RETAILER: {
        GET: {
            RETRIEVE_RETAILERS: RETAILER_API + '/api/retailers',
            CHECK_RETAILER: USER_API + '/api/dashboard/checkretailer',
            IS_RETAILER: RETAILER_API + '/api/retailers/isretailer',
            HAS_INTEGRATION: RETAILER_API + '/api/retailers/hasintegration',
        },
        POST: {
            REGISTER: USER_API + '/api/retailer/register',
            VERIFY: USER_API + '/api/retailer/verify',
            LOGIN: USER_API + '/api/retailer/login',
            CREATE_ADDRESS: USER_API + '/api/retaileraddress/createaddress',
        }
    },
    RETURN: {
        POST: {
            SAVE_ADDRESS: RETAILER_API + '/api/returnwizard/address',
            SAVE_RETAILER: RETAILER_API + '/api/returnwizard/retailer',
            SAVE_ORDER: RETAILER_API + '/api/returnwizard/order',
            SAVE_SELAZAR_ORDER: RETAILER_API + '/api/selazarintegration/order',
            SAVE_PRODUCT: RETAILER_API + '/api/returnwizard/product',
            SAVE_RETURN: RETAILER_API + '/api/returnwizard/return',
            SAVE_PARCEL_CONFIRMATION: RETAILER_API + '/api/returnwizard/parcelconfirmation',
            SAVE_STEP: RETAILER_API + '/api/returnwizard/savestep',
            SUBMIT: RETAILER_API + '/api/returnwizard/submit',
            LIMIT: RETAILER_API + '/api/retailerssettings/limit'
        },
        PUT: {
            CANCEL: RETAILER_API + '/api/returns/cancelreturn/',
            COLLECTION_DATE: RETAILER_API + '/api/returns/',
            RETAILER_CANCEL: RETAILER_API + '/api/retailers/returns/'
        },
        GET: {
            RETRIEVE_REASONS: RETAILER_API + '/api/returnreasons',
            SUMMARY: RETAILER_API + '/api/returnwizard/summary',
            RETRIEVE_RETURNS_BYADDRESSID: RETAILER_API + '/api/returns/returnsbyaddressid',
            CHECK_PERMITTED: RETAILER_API + '/api/returns/permitted',
            RETURNS: RETAILER_API + '/api/returns',
            RETAILER_RETURNS: RETAILER_API + '/api/retailers/returns',
            RETURN_DETAILS: RETAILER_API + '/api/returns/',
            RETAILER_RETURN_DETAILS: RETAILER_API + '/api/retailers/return/',
            SELAZAR_ORDER_PRODUCTS: RETAILER_API + '/api/selazarintegration/{retailerId}/reference',

        }
    },
    INVOICE: {
        GET: {
            INVOICES: RETAILER_API + '/api/invoices',
            DOWNLOAD: RETAILER_API + '/api/invoices/download/'
        }
    },
    SCORE: {
        GET: {
            PROFILE: USER_API + '/api/profile/getprofile',
            HISTORY: USER_API + '/api/robinscorehistory'
        }
    },
    SETTINGS: {
        GET: {
            INTEGRATIONS: RETAILER_API + '/api/retailerintegration',
            INTEGRATION: RETAILER_API + '/api/selazarintegration/',
            VALID: RETAILER_API + '/api/retailerintegration/valid/',
            RETAILER_ADDRESS: USER_API + '/api/retaileraddress',
            SELAZAR_WAREHOUSES: RETAILER_API + '/api/selazarintegration/warehouses/',
            ORDER_AGE: RETAILER_API + '/api/retailerssettings/orderage',
            VALID_SELAZAR_INTEGRATION: RETAILER_API + '/api/selazarintegration/valid/'
        },
        PUT: {
            INTEGRATION_SELAZAR: RETAILER_API + '/api/selazarintegration',
            ORDER_AGE: RETAILER_API + '/api/retailerssettings/orderage'
        },
        POST: {
            INTEGRATION_SELAZAR: RETAILER_API + '/api/selazarintegration'
        }
    },
    FINANCE: {
        CARD: {
            POST: {
                NEW: RETAILER_API + '/api/carddetails/new',
                CHECK: RETAILER_API + '/api/carddetails/check'
            },
            GET:{
                CURRENT: RETAILER_API + '/api/carddetails/current',
                LATEST: RETAILER_API + '/api/carddetails/latest'
            },
            PUT:{
                REPLACE: RETAILER_API + '/api/carddetails/replace',
            },
            DELETE:{
                REMOVE: RETAILER_API + '/api/carddetails/remove',
            }
        },
        DIRECT_DEBIT: {
            POST:{
                CREATE: RETAILER_API + '/api/directdebitdetails/createsession',
            },
            GET:{
                CURRENT: RETAILER_API + '/api/directdebitdetails/current',
                LATEST: RETAILER_API + '/api/directdebitdetails/latest',
                ENABLED: RETAILER_API + '/api/directdebitdetails/enabled'
            },
            PUT:{
                REMOVE: RETAILER_API + '/api/directdebitdetails/removecurrent',
                DISABLE: RETAILER_API + '/api/directdebitdetails/disablecurrent',
            }
        }
    }
}

export default Endpoints;

