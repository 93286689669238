import React from 'react';
import { Button } from 'react-bootstrap';
import LoadingBar from '../../common/LoadingBar';
import { NoReturns, ReturnsFilter, NoReturnsSearch } from '../shared/Snippets';
import PagingTable from '../../common/tables/PagingTable';
import { formatDate, getStatusClass } from '../../../Utilities';
import { GET } from '../../../Consumer';
import Endpoints from '../../common/Endpoints';

const HEADERS = ["Order Number", "Return Robin ID", "Date Created", "Collection Date", "Status"];

const ViewReturn = ({ id, history, orderID }) => {
    return <Button variant="link" className="m-0 underline btn btn-link" onClick={() => history.push(`/retailer/return/details/${id}`)}>{orderID}</Button>;
};

class Returns extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            pageIndex: 1,
            pageCount: 1,
            returns: [],
            filter: {
                searchField: "",
                dateCreatedDescending: true,
                dateOfCollectionDescending: null,
                status: null
            },
            sort: "DateCreatedDesc",
            firstRender: true
        };
    }

    async componentDidMount() {
        await this.getReturns();
    }

    getReturns = () => {
        const { pageIndex } = this.state;
        const filter = { ...this.state.filter };
        let url = new URL(Endpoints.RETURN.GET.RETAILER_RETURNS);

        Object.keys(filter).forEach(k => url.searchParams.append(k, filter[k]));
        url.searchParams.append("pageIndex", pageIndex);

        return GET(url)
            .catch(error => console.log(error))
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(results => {
                const returns = results ? results.data.results : [];
                const pageCount = results ? results.data.pageCount : 0;
                this.setState({ returns: returns, pageCount: pageCount, loading: false });
            });
    }

    getReturnRows = () => {
        const { returns } = this.state;
        const returnRows = [];
        returns.forEach(r => returnRows.push([<ViewReturn id={r.returnID} orderID={r.orderReference ? r.orderReference : r.orderID} history={this.props.history} />, r.uniqueReturnID, formatDate(r.createdDate), formatDate(r.preferredCollectionDateTime), <span className={getStatusClass(r.returnStatusValue)}>{r.returnStatusValue}</span>]));
        return returnRows;
    }

    onNext = async () => {
        const { pageIndex, pageCount } = this.state;
        if (pageIndex < pageCount) {
            this.setState({ pageIndex: parseInt(pageIndex) + 1, loading: true, firstRender: false }, this.getReturns);
        }
    }

    onEnd = async () => {
        const { pageIndex, pageCount } = this.state;
        if (pageIndex < pageCount) {
            this.setState({ pageIndex: pageCount, loading: true, firstRender: false }, this.getReturns);
        }
    }

    onPrevious = async () => {
        const { pageIndex } = this.state;
        if (pageIndex > 1) {
            this.setState({ pageIndex: parseInt(pageIndex) - 1, loading: true, firstRender: false }, this.getReturns);
        }
    }

    onStart = async () => {
        const { pageIndex } = this.state;
        if (pageIndex > 1) {
            this.setState({ pageIndex: 1, loading: true, firstRender: false }, this.getReturns);
        }
    }

    onClear = async (e) => {
        e.preventDefault();
        this.setState({
            filter: { searchField: "", dateCreatedDescending: true, dateOfCollectionDescending: null, status: null, firstRender: false }, loading: true
        }, this.getReturns);
    }

    onSearch = async (e) => {
        e.preventDefault();
        this.setState({ loading: true, firstRender: false });
        await this.getReturns();
    }

    onFilterChange = (e) => {
        const { name, value } = e.target;

        if (name === "sort") {
            this.setState({ sort: value });

            if (value === "DateCreatedAsc") {
                this.setState(prevState => ({
                    filter: {
                        ...prevState.filter,
                        dateCreatedDescending: false,
                        dateOfCollectionDescending: null,
                    }
                }));
            } else if (value === "CollectionDateAsc") {
                this.setState(prevState => ({
                    filter: {
                        ...prevState.filter,
                        dateCreatedDescending: false,
                        dateOfCollectionDescending: false,
                    }
                }));
            } else if (value === "CollectionDateDesc") {
                this.setState(prevState => ({
                    filter: {
                        ...prevState.filter,
                        dateCreatedDescending: false,
                        dateOfCollectionDescending: true,
                    }
                }));
            } else {
                this.setState(prevState => ({
                    filter: {
                        ...prevState.filter,
                        dateCreatedDescending: true,
                        dateOfCollectionDescending: null,
                    }
                }));
            }
        } else {
            this.setState(prevState => ({
                filter: {
                    ...prevState.filter,
                    ...{ [name]: value }
                }
            }));
        }
    }

    render() {

        const { loading, returns, firstRender, filter, sort, pageIndex, pageCount } = this.state;
        const noReturns = returns.length === 0;
        const isRetailer = JSON.parse(localStorage.getItem('isRetailer'));

        return (
            <div className="main-content">
                {loading && <LoadingBar />}
                <h3>Returns</h3>
                <h4 className="mb-3">Return Log</h4>
                {(noReturns && firstRender)
                    ? <NoReturns />
                    : <React.Fragment>
                        <ReturnsFilter filter={filter} sort={sort} onFilterChange={this.onFilterChange} onClear={this.onClear} onSearch={this.onSearch} isRetailer={isRetailer} />
                        {noReturns && <NoReturnsSearch />}
                        <PagingTable headers={HEADERS} rows={this.getReturnRows()} onNext={this.onNext} onPrevious={this.onPrevious} onStart={this.onStart} onEnd={this.onEnd} pageIndex={pageIndex} pageCount={pageCount} />
                    </React.Fragment>}

            </div>
        );
    }
}

export default Returns;