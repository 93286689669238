import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Form, FormGroup, FormLabel, FormControl, Button } from 'react-bootstrap';
import 'react-intl-tel-input/dist/main.css';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import FormValidator from '../../common/FormValidator';
import { extractData, POST, POST_WITH_HEADER } from '../../../Consumer';
import Endpoints from '../../common/Endpoints';
import ReCAPTCHA from "react-google-recaptcha";
import { setCaretPosition, isEmpty } from '../../../Utilities';
import RRLogo from '../../common/RRLogo'
import GoogleLogin from 'react-google-login';
import Mobile from '../../common/Mobile';
import { WarningAlert, ErrorAlert } from '../../common/Alerts';
import { withRouter } from 'react-router';
import NewPassword from '../../common/inputs/NewPassword';
import Confirmation from '../../common/Confirmation';
import LoadingBar from '../../common/LoadingBar';
import { STRONG_PASSWORD_PATTERN, NUMBER_PATTERN, SPECIAL_CHAR_PATTERN, LENGTH_PATTERN, CAPTCHA_KEY, PHONE_UTIL } from '../shared/Snippets';

import '../shared/Register.scss';

const GOOGLE_LOGIN_CLIENT_ID = process.env.REACT_APP_GOOGLE_LOGIN_CLIENT_ID;

class Register extends Component {

    constructor(props) {
        super(props);

        this.validator = new FormValidator([
            {
                field: 'email',
                method: 'isEmail',
                validWhen: true,
                message: 'This is not a valid email address, try again.'
            },
            {
                field: 'phoneNumber',
                method: 'isEmpty',
                validWhen: false,
                message: 'This is not a valid phone number, try again.'
            },
            {
                field: 'password',
                method: 'matches',
                args: [STRONG_PASSWORD_PATTERN],
                validWhen: true,
                message: 'Password does not meet the criteria, try again.'
            }
        ]);

        this.state = {
            form: {
                email: '',
                phoneNumber: '',
                countryCode: '',
                password: '',
                confirmation: false
            },
            recaptcha: false,
            recaptchError: false,
            passwordInputType: 'password',
            passwordInputIcon: faEye,
            passwordChecks: {
                passwordNumberCheck: false,
                passwordSpecialCharCheck: false,
                passwordLengthCheck: false
            },
            validation: this.validator.valid(),
            warning: false,
            errorMessages: [],
            heading: '',
            recaptchaError: false,
            signInRequired: false,
            googleMessages: [],
            submitted: false,
            loading: false,
            confirmationValidation: true,
        };
    }

    validate = (form) => {
        let validation = this.validator.validate(form);

        if (!isEmpty(form.phoneNumber)) {
            try {
                const phone = PHONE_UTIL.parse(form.phoneNumber, form.countryCode);
                if (!PHONE_UTIL.isValidNumber(phone)) {
                    validation.isValid = false;
                    validation.phoneNumber.isInvalid = true;
                    validation.phoneNumber.message = "This is not a valid phone number, try again.";
                }
            } catch (e) {
                console.log(e);
                validation.isValid = false;
                validation.phoneNumber.isInvalid = true;
                validation.phoneNumber.message = "This is not a valid phone number, try again.";
            }
        }
        return validation;
    }

    handleShowHidePassword = () => {
        const { passwordInputType } = this.state;

        passwordInputType === "password" ?
            this.setState({ passwordInputType: 'text', passwordInputIcon: faEyeSlash }) :
            this.setState({ passwordInputType: 'password', passwordInputIcon: faEye });
    }

    handleRecaptcha = () => {
        this.setState({ recaptcha: true });
    }

    handleInputChange = (e) => {
        let { name, value } = e.target;

        if (name === "password") {
            this.setState({
                passwordChecks: {
                    passwordNumberCheck: NUMBER_PATTERN.test(value),
                    passwordSpecialCharCheck: SPECIAL_CHAR_PATTERN.test(value),
                    passwordLengthCheck: LENGTH_PATTERN.test(value)
                }
            });
        }

        if (e.target.getAttribute('parent')) {
            let parent = e.target.getAttribute('parent');
            let parentValue = this.state.form[parent];
            parentValue[name] = value;

            name = parent;
            value = parentValue;
        }

        this.setState(prevState => ({
            ...prevState,
            form: {
                ...prevState.form,
                [name]: value
            }
        }));
    }

    handleConfirmationInputChange = () => {
        this.setState(prevState => ({
            ...prevState,
            form: {
                ...prevState.form,
                confirmation: !prevState.form.confirmation
            }
        }));
    }

    handlePhoneNumberChange = (...args) => {
        setCaretPosition(args[3].length);

        this.setState(prevState => ({
            ...prevState,
            form: {
                ...prevState.form,
                ...{
                    phoneNumber: args[3],
                    countryCode: args[2].iso2
                }
            }
        }));
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({ loading: true });

        const { form, recaptcha, confirmationValidation } = this.state;

        const validation = this.validate(form);
        this.setState({ validation: validation, recaptchaError: !recaptcha, submitted: true });
        this.setState({ confirmationValidation: form.confirmation });

        if (recaptcha && validation.isValid && confirmationValidation) {
            return POST(Endpoints.USER.POST.REGULAR_REGISTRATION, form)
                .then(response => response.json())
                .then((result) => {
                    if (result.error) {
                        if (result.data !== null) this.setState({ warning: result.data.warning ?? false, heading: result.data.heading, signInRequired: result.data.signInRequired ?? false, errorMessages: result.data.errorMessages ?? [], loading: false });
                        else this.setState({ errorMessages: result.message, loading: false });
                    }
                    else this.props.history.push({ pathname: "/register/accountverification", state: { userid: result.data.id, phoneNumber: result.data.phoneNumber } });
                })
                .catch(() => this.props.history.push({ pathname: "/register/issue" }));
        } else {
            this.setState({ loading: false });
        }
    }

    responseGoogleSuccess = (response) => {
        this.setState({ loading: true });
        POST_WITH_HEADER(Endpoints.USER.GET.GOOGLE_LOGIN, response.getAuthResponse().id_token)
            .then(response => response.json())
            .then((result) => {
                var data = result.data;
                if (result.error) {
                    if (data !== null) this.setState({ googleWarning: data.warning, heading: data.heading, signInRequired: data.signInRequired, errorMessages: data.errorMessages ?? [], loading: false });
                    this.setState({ googleMessages: result.message, loading: false });
                }
                else {
                    if (data.user === null) this.props.history.push({ pathname: "/register/mobile", state: { identifier: response.tokenId } });
                    else if (data.user.active === false) this.props.history.push({ pathname: "/register/accountverification", state: { userid: data.user.id, phoneNumber: data.user.phoneNumber } })
                    else {
                        this.props.history.push({ pathname: "/" });
                        this.props.onLogin(extractData(result), false);
                    }
                }
            })
            .catch(() => this.props.history.push({ pathname: "/register/issue" }));
    }

    responseGoogleFailure = (response) => {
        this.setState({ googleMessages: ["Cannot connect with Google"] });
    }

    renderForm = () => {
        let { form, errorMessages, passwordInputType, passwordInputIcon, passwordChecks, warning, heading, signInRequired, validation, submitted, confirmationValidation } = this.state;
        const emailClasses = validation.email.isInvalid ? "input-error" : undefined;
        const passwordStatus = validation.password.isInvalid ? "input-error" : undefined;

        return (
            <Form onSubmit={this.handleSubmit}>
                {errorMessages.length > 0 && <ErrorAlert messages={errorMessages} heading={heading} />}
                {warning && <WarningAlert linkRequired={signInRequired} message="The email address you have entered is associated with an account." link="login" linkText="Sign in" linkPostText="to use Return Robin." />}
                <FormGroup>
                    <FormLabel htmlFor="emailField">Email</FormLabel>
                    <FormControl id="emailField" className={emailClasses} type="email" name="email" maxLength="50" onChange={this.handleInputChange} value={form.email} />
                    <span className="text-danger">{validation.email.message}</span>
                </FormGroup>

                <Mobile value={form.phoneNumber} invalidPhoneNumber={validation.phoneNumber.isInvalid} handlePhoneNumberChange={this.handlePhoneNumberChange} validationMessage={validation.phoneNumber.message} />

                <NewPassword passwordStatus={passwordStatus} passwordInputType={passwordInputType} formPassword={form.password} handleInputChange={this.handleInputChange} handleShowHidePassword={this.handleShowHidePassword} passwordInputIcon={passwordInputIcon} validationPasswordMessage={validation.password.message} passwordChecks={passwordChecks} submitted={submitted} />

                <Confirmation
                    confirmation={form.confirmation}
                    confirmationValidation={confirmationValidation}
                    handleConfirmationInputChange={this.handleConfirmationInputChange}
                />


                <div className="center mt-4">
                    <ReCAPTCHA sitekey={CAPTCHA_KEY} onChange={this.handleRecaptcha} />
                </div>
                {this.state.recaptchaError && <p className="center text-danger">Please complete captcha</p>}

                <FormGroup className="mt-4">
                    <Button variant="primary" block type="submit" value="Submit">Create Account</Button>
                </FormGroup>
            </Form>

        );
    }

    renderGoogleLogin = () => {
        const { googleMessages, heading, googleWarning, signInRequired } = this.state;

        return (

            <React.Fragment>
                <h6> Create an account with social</h6>
                <p>Quick, easy and no extra passwords!</p>

                {googleMessages.length > 0 && <ErrorAlert messages={googleMessages} heading={heading} />}
                {googleWarning && <WarningAlert linkRequired={signInRequired} message="The email address you have entered is associated with an account." link="login" linkText="Sign in" linkPostText="to use Return Robin." />}

                <div className="google">
                    <GoogleLogin
                        clientId={GOOGLE_LOGIN_CLIENT_ID}
                        buttonText="Sign up with Google"
                        onSuccess={this.responseGoogleSuccess}
                        onFailure={this.responseGoogleFailure}
                        cookiePolicy={'single_host_origin'}
                        isSignedIn={false}
                        className="googleButton" />
                </div>

            </React.Fragment>
        )
    }

    render() {
        const { loading } = this.state;
        const { showLogo = true } = this.props;

        return (
            <div className="center">
                <div className="container">
                    {loading && <LoadingBar />}
                    {showLogo && <RRLogo />}
                    <div className="center-block mb-5">
                        <div>
                            <h3>Create an Account</h3>
                            {this.renderGoogleLogin()}
                            <div className="separator">or</div>
                            <h6>Create an account using your email address</h6>
                            {this.renderForm()}
                            <p className="text-center">Already have an account? <Link className="link-button" to={`/login`}>Sign in</Link></p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default withRouter(Register);