import React, { Component, Fragment } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import moment from 'moment-timezone';
import FormValidator from '../../../common/FormValidator';
import Endpoints from '../../../common/Endpoints';
import { GET, POST } from '../../../../Consumer';
import Stepper from '../../../common/wizard/Stepper';
import StepperButtons from '../../../common/wizard/StepperButtons';
import CreateReturnAddress from './CreateReturnAddress';
import CreateReturnRetailerDetails from './CreateReturnRetailerDetails';
import { ReturnOrderDetails, ReturnOrderSelazarIntDetails } from './CreateReturnOrderDetails';
import CreateReturnProductDetails from './CreateReturnProductDetails';
import CreateReturnDetails from './CreateReturnDetails';
import CreateReturnSummary from './CreateReturnSummary';
import CreateReturnConfirmation from './CreateReturnConfirmation';
import LoadingBar from '../../../common/LoadingBar';
import ReturnPermissionDenied from './ReturnPermissionDenied';
import Confirm from '../../../common/modals/Confirm';
import { ErrorAlert } from '../../../common/Alerts';
import { CREATE_RETURN_STEPS } from '../../../common/constants';

import './ReturnWizard.scss';

const CANCEL_TEXT = "Cancel Create Return";
const CANCEL_LINK = "/";
const REASON_OTHER = "Other";

let collectionMinDate = new Date();
collectionMinDate.setDate(collectionMinDate.getDate() + 2);
let acceptableDate = new Date();
acceptableDate.setDate(collectionMinDate.getDate() - 1);
let maxDate = new Date();
maxDate.setDate(maxDate.getDate());

class CreateReturnWizard extends Component {

    retailerValidator = new FormValidator([
        {
            field: 'id',
            method: 'isEmpty',
            validWhen: false,
            message: 'Please select a retailer'
        }
    ]);

    orderValidator = new FormValidator([
        {
            field: 'orderDate',
            method: 'isEmpty',
            validWhen: false,
            message: 'Enter a valid order date to continue'
        },
        {
            field: 'orderID',
            method: 'isEmpty',
            validWhen: false,
            message: 'Enter your order ID to continue'
        },
        {
            field: 'emailAddress',
            method: 'isEmail',
            validWhen: true,
            message: 'Enter the email address associated with your order to continue'
        }
    ]);

    selazarOrderValidator = new FormValidator([
        {
            field: 'orderReference',
            method: 'isEmpty',
            validWhen: false,
            message: 'Enter your order reference to continue'
        },
        {
            field: 'emailAddress',
            method: 'isEmail',
            validWhen: true,
            message: 'Enter the email address associated with your order to continue'
        }
    ]);

    productValidator = new FormValidator([
        {
            field: 'returnReason',
            method: 'isEmpty',
            validWhen: false,
            message: 'Select a return reason to continue'
        }
    ]);

    productReasonOtherValidator = new FormValidator([
        {
            field: 'returnReasonInformation',
            method: 'isEmpty',
            validWhen: false,
            message: 'Enter the reason to continue'
        }
    ]);

    productReasonAdditionalInformationValidator = new FormValidator([
        {
            field: 'additionalInformation',
            method: 'isEmpty',
            args: [ { ignore_whitespace: true } ],
            validWhen: false,
            message: 'Enter the additional information to continue'
        }
    ])

    returnValidator = new FormValidator([
        {
            field: 'collectionDate',
            method: 'isAfter',
            args: [acceptableDate.toDateString()],
            validWhen: true,
            message: 'Select a collection date to continue'
        }
    ]);

    state = {
        pageIndex: 1,
        pageCount: 1,
        currentPage: [],
        activeStep: 0,
        show: false,
        returnWizardDetails: {
            id: '',
            userid: JSON.parse(localStorage.getItem('user')).user.id,
            payload: {
                addressDetails: {
                    id: '',
                    summary: ''
                },
                retailerDetails: {
                    id: '',
                    name: ''
                },
                orderDetails: {
                    emailAddress: '',
                    orderID: '',
                    orderDate: ''
                },
                selazarOrderDetails: {
                    orderID: '',
                    emailAddress: '',
                    orderReference: '',
                    products: [],
                    orderDate: ''
                },
                productDetails: {
                    returnReason: '',
                    returnReasonInformation: '',
                    additionalInformation: ''
                },
                returnDetails: {
                    collectionDate: ''
                },
                parcelConfirmationDetails: {
                    confirmation: false
                }
            }
        },
        summary: {
            selectedAddress: '',
            selectedRetailer: '',
            emailAddress: '',
            orderId: '',
            orderDate: new Date(),
            returnReason: '',
            otherReason: false,
            returnReasonInformation: '',
            collectionDate: new Date()
        },
        userAddresses: [],
        error: false,
        errorMessages: [],
        loading: true,
        retailerDetailsValidation: this.retailerValidator.valid(),
        orderDetailsValidation: this.orderValidator.valid(),
        selazarOrderValidation: this.selazarOrderValidator.valid(),
        productDetailsValidation: this.productValidator.valid(),
        productReasonOtherValidation: this.productReasonOtherValidator.valid(),
        productReasonAdditionalInformationValidation: this.productReasonAdditionalInformationValidator.valid(),
        returnDetailsValidation: this.returnValidator.valid(),
        parcelConfirmationValidation: true,
        permitted: false,
        permittedErrors: false,
        orderDateText: "",
        activeSelazarIntegration: false,
        showOrderAgeLimitError: false,
        orderProductDetails: {},
        showProducts: false,
        showProductsOrderAgeError: false,
        showProductsError: false,
        showProductSelectError: false
    };

    async componentDidMount() {
        await this.checkReturnsLimit();
        this.setState({ loading: false });
    }

    // API Calls
    checkReturnsLimit = async () =>
        GET(Endpoints.RETURN.GET.CHECK_PERMITTED)
            .then(response => response.json())
            .then(result => {
                if (result.error) {
                    result.data
                        ? this.setState({ permittedErrors: result.data })
                        : this.setState({ permittedErrors: result.message });
                } else {
                    const data = result.data;
                    this.setState({ permitted: data })
                    if (data) this.fetchAddresses();
                }
            }).catch(() => this.setState({ permittedErrors: ["There has been an issue starting the return."] }));

    fetchAddresses = async () =>
        GET(Endpoints.ADDRESS.GET.ADDRESSES_BY_USERID)
            .then(response => response.json())
            .then(result => {
                if (result.error) {
                    if (result.data) this.setState({ errorMessages: result.data });
                    else this.setState({ errorMessages: result.message });
                }
                else {
                    const userAddresses = result.data ? result.data : [];
                    if (userAddresses.length > 0) {
                        const defaultAddress = userAddresses.find(a => a.default === true);

                        if (defaultAddress !== undefined) {
                            this.handleAddressSelection(defaultAddress.id, defaultAddress.address);
                        }
                    }
                    this.setState({ userAddresses: userAddresses });
                }
            }).catch(() => this.setState({ errorMessages: ["There has been an issue retrieving addresses."] }));

    selazarIntegrationValid = async () => {
        const { returnWizardDetails } = this.state;
        const retailerId = returnWizardDetails.payload.retailerDetails.id;

        return GET(new URL(retailerId, Endpoints.SETTINGS.GET.VALID_SELAZAR_INTEGRATION))
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(result => {
                const activeSelazarIntegration = result ? result.data : false;
                this.setState({ activeSelazarIntegration: activeSelazarIntegration });
            })
            .catch(error => console.log(error));
    }

    fetchSelazarOrderDetails = async () => {
        const { returnWizardDetails } = this.state;
        const retailerId = returnWizardDetails.payload.retailerDetails.id;
        const orderReference = returnWizardDetails.payload.selazarOrderDetails.orderReference;
        const email = returnWizardDetails.payload.selazarOrderDetails.emailAddress;

        const url = new URL(Endpoints.RETURN.GET.SELAZAR_ORDER_PRODUCTS.replace("{retailerId}", retailerId));
        url.searchParams.append("orderreference", encodeURIComponent(orderReference));
        url.searchParams.append("returnemail", encodeURIComponent(email));

        return GET(url)
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(result => {
                const orderDetails = result ? result.data : null;

                if (orderDetails === null) {
                    this.setState({ showProductsError: true, showProductsOrderAgeError: false });
                } else {
                    orderDetails?.orderLimitReached
                        ? this.setState({ showProductsError: false, showProductsOrderAgeError: true })
                        : this.setState({ orderProductDetails: { orderDate: orderDetails.createdDate, products: orderDetails.items }, 
                            showProducts: true, showProductsError: false, showProductsOrderAgeError: false });
                    
                        //set orderID within current selazarOrderDetails state
                        this.setState(prevState => ({
                            ...prevState,
                            returnWizardDetails: {
                                ...prevState.returnWizardDetails,
                                payload: {
                                    ...prevState.returnWizardDetails.payload, 
                                    selazarOrderDetails: {
                                       ...prevState.returnWizardDetails.payload.selazarOrderDetails,
                                       orderID: orderDetails.orderID,
                                       orderDate: orderDetails.createdDate
                                    }
                                }
                            }
                        }))

                }
            })
            .catch(error => console.log(error));
    }

    fetchValidOrderLimit = async (orderAgeLimitRetailer) =>
        POST(Endpoints.RETURN.POST.LIMIT, orderAgeLimitRetailer)
            .then(response => response.json())
            .then(result => {
                return result ? result.data : false;
            })
            .catch(error => {
                console.log(error);
                return false;
            });

    saveStep = async (url, payload, moveStep = true) => {
        const { returnWizardDetails } = this.state;
        const wizardId = returnWizardDetails.id ? returnWizardDetails.id : "";

        return POST(url + "/" + wizardId, payload)
            .then(response => response.json())
            .then((result) => {
                if (result.error) {
                    if (result.data) this.setState({ errorMessages: result.data });
                    else this.setState({ errorMessages: result.message });
                }
                else {
                    this.setState(prevState => ({
                        ...prevState,
                        returnWizardDetails: {
                            ...prevState.returnWizardDetails,
                            id: result.data
                        },
                        errorMessages: []
                    }));

                    if (moveStep) this.nextStep();
                }
            }).catch(() => this.setState({ errorMessages: ["There has been an issue saving this step."] }));;
    }

    retrieveSummary = () => {
        this.setState({ loading: true });
        const { returnWizardDetails } = this.state;
        const wizardId = returnWizardDetails.id ? returnWizardDetails.id : "";

        return GET(Endpoints.RETURN.GET.SUMMARY + "/" + wizardId)
            .then(response => response.json())
            .then((result) => {
                if (result.error) {
                    if (result.data) this.setState({ errorMessages: result.data });
                    else this.setState({ errorMessages: result.message });
                }
                else {
                    this.setState(prevState => ({
                        ...prevState,
                        summary: result.data
                    }));
                    this.setState({ loading: false });
                }
            }).catch(() => this.setState({ errorMessages: ["There has been an issue retrieving the summary."], loading: false }));
    }

    submitReturn = async () => {
        this.setState({ loading: true });
        const { returnWizardDetails } = this.state;
        const wizardId = returnWizardDetails.id ? returnWizardDetails.id : "";

        // Validate parcel confirmation
        this.setState({ parcelConfirmationValidation: returnWizardDetails.payload.parcelConfirmationDetails.confirmation });

        if (returnWizardDetails.payload.parcelConfirmationDetails.confirmation) {
            await this.saveStep(Endpoints.RETURN.POST.SAVE_PARCEL_CONFIRMATION, returnWizardDetails.payload.parcelConfirmationDetails, false);

            return POST(Endpoints.RETURN.POST.SUBMIT + "/" + wizardId)
                .then(response => response.json())
                .then((result) => {
                    if (result.error) {
                        if (result.data) this.setState({ errorMessages: result.data });
                        else this.setState({ errorMessages: result.message });
                    }
                    else {
                        this.setState(prevState => ({
                            activeStep: prevState.activeStep + 1,
                            errorMessages: []
                        }));
                    }
                    this.setState({ loading: false });
                }).catch(() => this.setState({ errorMessages: ["There has been an issue submitting the return."], loading: false }));
        } else this.setState({ loading: false });
    }

    // Modal
    handleShow = (e) => {
        e.preventDefault();
        this.setState({ show: true });
    }

    handleClose = () => this.setState({ show: false });

    handleCloseModal = (e) => {
        e.preventDefault();
        this.setState({ show: false });
    }

    handleCancelAccount = (e) => {
        e.preventDefault();
        this.props.history.push("/");
    }

    // Access Denied
    handleOkButton = () => this.props.history.push('/return');

    // Stepper
    handleNext = async () => {
        const { activeStep, returnWizardDetails, orderDateText, activeSelazarIntegration, showProducts } = this.state;
        this.setState({ loading: true });
        let validate = null;
        let updatedOrderDetails = {};

        if (activeStep === 0) validate = await this.validateAddressDetails(returnWizardDetails.payload.addressDetails);
        else if (activeStep === 1) validate = await this.validateRetailerDetails(returnWizardDetails.payload.retailerDetails);
        else if (activeStep === 2) {
            if (activeSelazarIntegration) {
                validate = await this.validateSelazarOrderDetails(returnWizardDetails.payload.selazarOrderDetails);
                if (validate.isValid && !showProducts) {
                    await this.fetchSelazarOrderDetails();
                } else if (validate.isValid && showProducts && returnWizardDetails.payload.selazarOrderDetails.products.length === 0) {
                    this.setState({ showProductSelectError: true });
                    validate.isValid = false;
                }
            } else {
                this.setState({ showOrderAgeLimitError: false });
                const dateNoSpaces = orderDateText.replace(/\s/g, '');
                const dateMomentObject = moment(dateNoSpaces, "DDMMYYYY");
                const dateObject = dateMomentObject.toDate();

                if (!dateMomentObject.isValid() || dateObject > maxDate) {
                    validate = await this.validateOrderDetails({ ...returnWizardDetails.payload.orderDetails, orderDate: "" });
                }
                else {
                    updatedOrderDetails = { ...returnWizardDetails.payload.orderDetails, orderDate: dateObject }
                    this.setState(prevState => ({
                        returnWizardDetails: {
                            ...prevState.returnWizardDetails,
                            payload: {
                                ...prevState.returnWizardDetails.payload,
                                orderDetails: updatedOrderDetails
                            }
                        }
                    }));
                    validate = await this.validateOrderDetails(updatedOrderDetails);

                    const orderAgeLimitRetailer = {
                        retailerID: returnWizardDetails.payload.retailerDetails.id,
                        orderDate: dateObject.toDateString()
                    }

                    const validOrderAgeLimit = await this.fetchValidOrderLimit(orderAgeLimitRetailer);

                    if (!validOrderAgeLimit) {
                        this.setState({ showOrderAgeLimitError: true });
                        validate.isValid = false;
                    }
                }
            }
        }
        else if (activeStep === 3) validate = await this.validateProductDetails(returnWizardDetails.payload.productDetails);
        else if (activeStep === 4) validate = await this.validateReturnDetails(returnWizardDetails.payload.returnDetails);

        if ((validate !== null && validate.isValid)) {
            if (activeStep === 0) await this.saveStep(Endpoints.RETURN.POST.SAVE_ADDRESS, returnWizardDetails.payload.addressDetails);
            else if (activeStep === 1) {
                await this.saveStep(Endpoints.RETURN.POST.SAVE_RETAILER, returnWizardDetails.payload.retailerDetails);
                await this.selazarIntegrationValid();
            }
            else if (activeStep === 2) {
                if (activeSelazarIntegration) {
                    if (showProducts) {
                        if (returnWizardDetails.payload.selazarOrderDetails.products.length > 0) {
                            await this.saveStep(Endpoints.RETURN.POST.SAVE_SELAZAR_ORDER, returnWizardDetails.payload.selazarOrderDetails);
                            this.setState({ showProductSelectError: false });
                        } else {
                            this.setState({ showProductSelectError: true });
                        }
                    }
                } else {
                    const orderDateMoment = moment.utc(updatedOrderDetails.orderDate).tz("Europe/London");
                    var orderDetails = {
                        emailAddress: returnWizardDetails.payload.orderDetails.emailAddress,
                        orderID: returnWizardDetails.payload.orderDetails.orderID,
                        orderDate: orderDateMoment.format().split("T")[0]
                    };
                    await this.saveStep(Endpoints.RETURN.POST.SAVE_ORDER, orderDetails);
                }
            }
            else if (activeStep === 3) await this.saveStep(Endpoints.RETURN.POST.SAVE_PRODUCT, returnWizardDetails.payload.productDetails);
            else if (activeStep === 4) {
                const collectionDateMoment = moment.utc(returnWizardDetails.payload.returnDetails.collectionDate).tz("Europe/London");
                const returnDetails = {
                    collectionDate: collectionDateMoment.format().split("T")[0]
                }
                await this.saveStep(Endpoints.RETURN.POST.SAVE_RETURN, returnDetails);
            }
        }
        this.setState({ loading: false });
    }

    nextStep = () => {
        this.setState(state => ({
            activeStep: state.activeStep + 1,
            error: false,
            errorMessages: []
        }));

        if (this.state.activeStep === 5) this.retrieveSummary();
    }

    handleBack = () => {
        const { activeStep, showProducts } = this.state;

        if (activeStep === 2 && showProducts) {
            this.setState(prevState => ({
                orderProductDetails: {},
                showProducts: false,
                returnWizardDetails: {
                    ...prevState.returnWizardDetails,
                    payload: {
                        ...prevState.returnWizardDetails.payload,
                        selazarOrderDetails: { ...prevState.returnWizardDetails.payload.selazarOrderDetails, products: [] }
                    }
                }
            }));
        }

        this.setState(state => ({
            activeStep: state.activeStep - 1,
            error: false,
            errorMessages: []
        }));
    };

    // Validation
    setValidationState = async (validation, text) => {
        if (validation.isValid) {
            validation.isValid = true;
            this.setState({ errorMessages: [] });
        }
        else {
            validation.isValid = false;
            text && this.setState({ errorMessages: [text] });
        }

        return validation;
    }

    validateAddressDetails = async (addressDetails) => {
        if (addressDetails !== null && addressDetails.id !== "" && addressDetails.id !== undefined) {
            return { isValid: true };
        }
        else {
            this.setState({ errorMessages: ["You must select an address to continue"] });
            return { isValid: false };
        }
    }

    validateRetailerDetails = async (retailerDetails) => {

        let retailerValidation = this.retailerValidator.validate(retailerDetails);
        this.setState({ retailerValidation: retailerValidation });

        return await this.setValidationState(retailerValidation, "You must select a retailer");
    }

    validateOrderDetails = async (orderDetails) => {

        let orderValidation = this.orderValidator.validate(orderDetails);
        this.setState({ orderDetailsValidation: orderValidation });

        return await this.setValidationState(orderValidation);
    }

    validateSelazarOrderDetails = async (selazarOrderDetails) => {
        const selazarOrderValidation = this.selazarOrderValidator.validate(selazarOrderDetails)
        this.setState({ selazarOrderValidation: selazarOrderValidation });
        return await this.setValidationState(selazarOrderValidation);
    }

    validateProductDetails = async (productDetails) => {
        if (productDetails.returnReason === REASON_OTHER) {
            let productValidation = this.productValidator.validate(productDetails);
            let productReasonOtherValidation = this.productReasonOtherValidator.validate(productDetails);
            let productReasonAdditionalInformationValidation = this.productReasonAdditionalInformationValidator.validate(productDetails);

            this.setState({ productDetailsValidation: productValidation, productReasonOtherValidation: productReasonOtherValidation, productReasonAdditionalInformationValidation });

            return productValidation.isValid && productReasonOtherValidation.isValid ? this.setValidationState(productReasonAdditionalInformationValidation) : productValidation.isValid ? this.setValidationState(productReasonOtherValidation) : this.setValidationState(productValidation);
        } else {
            let productValidation = this.productValidator.validate(productDetails);
            let productReasonAdditionalInformationValidation = this.productReasonAdditionalInformationValidator.validate(productDetails);
            this.setState({ productDetailsValidation: productValidation, productReasonOtherValidation: this.productReasonOtherValidator.valid(), productReasonAdditionalInformationValidation });

            return await (productValidation.isValid ? this.setValidationState(productReasonAdditionalInformationValidation) : this.setValidationState(productValidation))
        }
    }

    validateReturnDetails = async (returnDetails) => {

        const formattedReturnDetails = { collectionDate: returnDetails.collectionDate === "" ? new Date(0).toDateString() : new Date(returnDetails.collectionDate).toDateString() }
        let returnValidation = this.returnValidator.validate(formattedReturnDetails);
        this.setState({ returnDetailsValidation: returnValidation });

        return await this.setValidationState(returnValidation);
    }

    // Input handlers
    handleAddressSelection = (id, address) => {
        const addressLines = address.line2
            ? `${address.line1}, ${address.line2}, `
            : `${address.line1}, `;
        const summary = `${address.name}, ${addressLines} ${address.city}, ${address.county}, ${address.postcode}`

        this.setState(prevState => ({
            returnWizardDetails: {
                ...prevState.returnWizardDetails,
                payload: {
                    ...prevState.returnWizardDetails.payload,
                    addressDetails: {
                        ...prevState.returnWizardDetails.addressDetails,
                        id: id,
                        summary: summary
                    }
                }
            }
        }));
    }

    handleRetailerSelection = (e) => {
        const { id, name } = e.target;

        let checkboxes = [...document.getElementsByClassName("retailerCheckBox")].filter(e => e.id !== id);
        for (var i = 0; i < checkboxes.length; i++) {
            checkboxes[i].checked = false;
        }

        this.setState(prevState => ({
            returnWizardDetails: {
                ...prevState.returnWizardDetails,
                payload: {
                    ...prevState.returnWizardDetails.payload,
                    retailerDetails: {
                        ...prevState.returnWizardDetails.payload.retailerDetails,
                        id: id,
                        name: name
                    }
                }
            }
        }));
    }

    handleOrderDetailsInputChange = (e) => {
        const { name, value } = e.target;

        this.setState(prevState => ({
            returnWizardDetails: {
                ...prevState.returnWizardDetails,
                payload: {
                    ...prevState.returnWizardDetails.payload,
                    orderDetails: {
                        ...prevState.returnWizardDetails.payload.orderDetails,
                        [name]: value
                    }
                }
            }
        }));
    }

    handleSelazarOrderDetailsInputChange = (name, value) =>
        this.setState(prevState => ({
            returnWizardDetails: {
                ...prevState.returnWizardDetails,
                payload: {
                    ...prevState.returnWizardDetails.payload,
                    selazarOrderDetails: { ...prevState.returnWizardDetails.payload.selazarOrderDetails, [name]: value }
                }
            }
        }));

    handleProductSelect = (index) => {
        const { returnWizardDetails, orderProductDetails } = this.state;
        const selectedProducts = returnWizardDetails.payload.selazarOrderDetails.products;

        let updatedSelectedProducts = [];
        const addSelectedProduct = selectedProducts.find(p => p.index === index) === undefined;
        if (addSelectedProduct) {
            selectedProducts.push(orderProductDetails.products.find(p => p.index === index));
            updatedSelectedProducts = selectedProducts;
        } else {
            updatedSelectedProducts = selectedProducts.filter(sp => sp.index !== index);
        }

        this.setState(prevState => ({
            returnWizardDetails: {
                ...prevState.returnWizardDetails,
                payload: {
                    ...prevState.returnWizardDetails.payload,
                    selazarOrderDetails: { ...prevState.returnWizardDetails.payload.selazarOrderDetails, products: updatedSelectedProducts }
                }
            }
        }));
    }

    handleProductDetailsInputChange = (e) => {
        const { name, value } = e.target;

        this.setState(prevState => ({
            returnWizardDetails: {
                ...prevState.returnWizardDetails,
                payload: {
                    ...prevState.returnWizardDetails.payload,
                    productDetails: {
                        ...prevState.returnWizardDetails.payload.productDetails,
                        returnReasonInformation: (name === "returnReason" && value !== REASON_OTHER) ? '' : prevState.returnWizardDetails.payload.productDetails.returnReasonInformation,
                        [name]: value
                    }
                }
            }
        }));
    }

    handleReturnDetailsInputChange = (date) => {
        this.setState(prevState => ({
            returnWizardDetails: {
                ...prevState.returnWizardDetails,
                payload: {
                    ...prevState.returnWizardDetails.payload,
                    returnDetails: {
                        collectionDate: date
                    }
                }
            }
        }));
    }

    handleParcelConfirmationInputChange = () => {
        this.setState(prevState => ({
            returnWizardDetails: {
                ...prevState.returnWizardDetails,
                payload: {
                    ...prevState.returnWizardDetails.payload,
                    parcelConfirmationDetails: {
                        confirmation: !prevState.returnWizardDetails.payload.parcelConfirmationDetails.confirmation
                    }
                }
            }
        }));
    }

    handleOrderDetailsOrderDateInputChange = (dateText) => {
        const { orderDateText } = this.state;

        if (dateText.length < orderDateText.length) {
            this.setState({ orderDateText: dateText });
        } else {
            const dateNoSpaces = dateText.replace(/\s/g, '')
            this.setState({ orderDateText: dateNoSpaces.replace(/^(.{2})(.{2})(.*)$/, "$1 $2 $3") });
        }
    }

    render() {
        const { activeStep, permitted, permittedErrors, summary, retailerValidation, orderDetailsValidation, selazarOrderValidation, productDetailsValidation, productReasonOtherValidation, productReasonAdditionalInformationValidation, returnDetailsValidation, parcelConfirmationValidation, returnWizardDetails, userAddresses, errorMessages, orderDateText, activeSelazarIntegration, showOrderAgeLimitError, orderProductDetails, showProducts, showProductSelectError, showProductsOrderAgeError, showProductsError, loading } = this.state;
        const addressDetails = returnWizardDetails.payload.addressDetails;
        const retailerDetails = returnWizardDetails.payload.retailerDetails;
        const orderDetails = returnWizardDetails.payload.orderDetails;
        const selazarOrderDetails = returnWizardDetails.payload.selazarOrderDetails;
        const productDetails = returnWizardDetails.payload.productDetails;
        const returnDetails = returnWizardDetails.payload.returnDetails;
        const parcelConfirmationDetails = returnWizardDetails.payload.parcelConfirmationDetails;

        return (<Fragment>
            {loading
                ? <LoadingBar />
                : (permitted.lowScore === false && permitted.tooManyReturns === false)
                    ? <div className="main-content">
                        <div className="main-container">
                            <h3>Create Return</h3>
                            <Stepper steps={CREATE_RETURN_STEPS} activeStep={activeStep} />
                            <div className="pt-3">
                                {errorMessages.length > 0 && <ErrorAlert messages={errorMessages} />}
                                {activeStep === 0
                                    ? <Fragment>
                                        <CreateReturnAddress userAddresses={userAddresses} selectedAddressId={addressDetails.id} handleAddressSelection={this.handleAddressSelection} />
                                        <StepperButtons onClickCancel={this.handleShow} cancelLink={CANCEL_LINK} handleNext={this.handleNext} showNext={true} />
                                    </Fragment>
                                    : activeStep === 1
                                        ? <Fragment>
                                            <CreateReturnRetailerDetails retailerDetails={retailerDetails} retailerValidation={retailerValidation} handleRetailerSelection={this.handleRetailerSelection} />
                                            <StepperButtons onClickCancel={this.handleShow} cancelText={CANCEL_TEXT} handleBack={this.handleBack} showBack={true} cancelLink={CANCEL_LINK} handleNext={this.handleNext} showNext={true} />
                                        </Fragment>
                                        : activeStep === 2
                                            ? <Fragment>
                                                {showOrderAgeLimitError && <ErrorAlert messages="The return is not within that retailer's return time window." />}
                                                {activeSelazarIntegration
                                                    ? <ReturnOrderSelazarIntDetails
                                                        orderProductDetails={orderProductDetails}
                                                        selazarOrderDetails={selazarOrderDetails}
                                                        selazarOrderValidation={selazarOrderValidation}
                                                        handleSelazarOrderDetailsInputChange={this.handleSelazarOrderDetailsInputChange}
                                                        showProducts={showProducts}
                                                        handleProductSelect={this.handleProductSelect}
                                                        showProductSelectError={showProductSelectError}
                                                        showProductsOrderAgeError={showProductsOrderAgeError}
                                                        showProductsError={showProductsError}
                                                    />
                                                    : <ReturnOrderDetails
                                                        handleOrderDetailsOrderDateInputChange={this.handleOrderDetailsOrderDateInputChange}
                                                        orderDetails={orderDetails}
                                                        orderDateText={orderDateText}
                                                        orderValidation={orderDetailsValidation}
                                                        handleOrderDetailsInputChange={this.handleOrderDetailsInputChange}
                                                    />
                                                }
                                                <StepperButtons onClickCancel={this.handleShow} cancelText={CANCEL_TEXT} 
                                                    showBack={true} handleBack={this.handleBack} cancelLink={CANCEL_LINK} 
                                                    handleNext={this.handleNext} showNext={true} />
                                            </Fragment>
                                            : activeStep === 3
                                                ? <Fragment>
                                                    <CreateReturnProductDetails productDetails={productDetails} productValidation={productDetailsValidation} productReasonOtherValidation={productReasonOtherValidation} handleProductDetailsInputChange={this.handleProductDetailsInputChange} productReasonAdditionalInformationValidation={productReasonAdditionalInformationValidation}/>
                                                    <StepperButtons onClickCancel={this.handleShow} cancelText={CANCEL_TEXT} showBack={true} handleBack={this.handleBack} cancelLink={CANCEL_LINK} handleNext={this.handleNext} showNext={true} />
                                                </Fragment>
                                                : activeStep === 4
                                                    ? <Fragment>
                                                        <CreateReturnDetails returnDetails={returnDetails} returnValidation={returnDetailsValidation} handleReturnDetailsInputChange={this.handleReturnDetailsInputChange} />
                                                        <StepperButtons onClickCancel={this.handleShow} cancelText={CANCEL_TEXT} showBack={true} handleBack={this.handleBack} cancelLink={CANCEL_LINK} handleNext={this.handleNext} showNext={true} />
                                                    </Fragment>
                                                    : activeStep === 5
                                                        ? <Fragment>
                                                            <CreateReturnSummary summary={summary} parcelConfirmationDetails={parcelConfirmationDetails} handleParcelConfirmationInputChange={this.handleParcelConfirmationInputChange} parcelConfirmationValidation={parcelConfirmationValidation} />
                                                            <StepperButtons nextButtonText="Submit Return" onClickCancel={this.handleShow} cancelText={CANCEL_TEXT} showBack={true} handleBack={this.handleBack} cancelLink={CANCEL_LINK} handleNext={this.submitReturn} showNext={true} />
                                                        </Fragment>
                                                        : activeStep === 6
                                                            ? <Fragment>
                                                                <CreateReturnConfirmation summary={summary} showProducts={showProducts} />
                                                                <Row>
                                                                    <Col xs={12} sm={12} md={{ span: 4, offset: 8 }}>
                                                                        <Button variant="primary" block onClick={() => this.props.history.push('/return')}>Continue to Returns</Button>
                                                                    </Col>
                                                                </Row>
                                                            </Fragment>
                                                            : null
                                }
                            </div>
                        </div>
                        <Confirm handleConfirmAction={this.handleCancelAccount} title={CANCEL_TEXT} variant="outline-danger" block="true" buttonText={CANCEL_TEXT} text="Are you sure that you want to cancel creating this return? We will not save your progress." show={this.state.show} handleClose={this.handleClose} handleCancelAccount={this.handleCancelAccount} handleCloseModal={this.handleCloseModal} closeLink={true} linkText="Return to create return" />
                    </div>
                    : permittedErrors.length > 0
                        ? <ErrorAlert messages={permittedErrors} />
                        : <ReturnPermissionDenied handleOkButton={this.handleOkButton} tooManyReturns={permitted.tooManyReturns} robinScore={permitted.robinScore} />
            }
        </Fragment>);

    }
}

export default CreateReturnWizard;