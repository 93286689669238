import React from 'react';
import { Card } from 'react-bootstrap';

const ReturnAddress = ({ address, addressID, selected = false, handleAddressSelection }) =>
    <Card className={`p-3 mb-3 card-list d-block ${selected ? "return-address-selected" : "return-address"}`} onClick={() => handleAddressSelection(addressID, address)}>
        <div className="card-list-info">
            <p className="mb-1 font-weight-bold">{address.name}</p>
            <p className="mb-1">{address.line1}</p>
            {address.line2 && <p className="mb-1">{address.line2}</p>}
            <p className="mb-1">{address.city}</p>
            <p className="mb-1">{address.county}</p>
            <p className="mb-1">{address.postcode}</p>
        </div>
        <span className="address-radio">
            {selected && <span className="address-radio-selected"></span>}
        </span>
    </Card>

export default ReturnAddress;