import React, { Component } from 'react';
import { FormControl, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

const CustomDateInput = ({ value, onClick, errorClass }) => (
    <InputGroup className={`date-custom-container ${errorClass}`}>
        <FormControl placeholder="dd/mm/yyyy" onClick={onClick} value={value} />
        <InputGroup.Append>
            <InputGroup.Text className="input-icon">
                <FontAwesomeIcon icon={faCalendar} />
            </InputGroup.Text>
        </InputGroup.Append>
    </InputGroup>
)

class CustomDatePicker extends Component {

    render() {
        const { errorClass = "", filterDate, minDate, maxDate, selected, handleInputChange } = this.props;
        
        return (
            <DatePicker dateFormat="dd/MM/yyyy" customInput={<CustomDateInput errorClass={errorClass} />} 
                minDate={minDate} maxDate={maxDate} filterDate={filterDate} 
                selected={selected} onChange={(date) => handleInputChange(date)}></DatePicker>
        )
    }
}

export default CustomDatePicker;