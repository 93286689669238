import React, { Fragment, useCallback } from 'react';
import { FormGroup, FormLabel } from 'react-bootstrap';
import CustomDatePicker from '../../../common/inputs/CustomDatePicker';
import { bankHolidays } from '../../shared/BankHolidays';

let minDate = new Date();
minDate.setDate(minDate.getDate() + 2);
let maxDate = new Date();
maxDate.setDate(maxDate.getDate() + 16);

const CreateReturnDetails = ({ returnDetails, returnValidation, handleReturnDetailsInputChange }) => {
    const isNotSundayOrBankHoliday = useCallback((date) => {
        const dateAsString = date.toDateString();
        const day = date.getDay();
        return day !== 0 && !bankHolidays.includes(dateAsString);
    },[]);

    return (<Fragment>
        <h4 className="mt-2 mb-3">Return Details</h4>
        <FormGroup>
            <FormLabel htmlFor="collectionDateField">Collection Date</FormLabel>
            <CustomDatePicker
                errorClass={returnValidation.collectionDate.isInvalid ? "input-error" : ""}
                filterDate={isNotSundayOrBankHoliday}
                minDate={minDate}
                maxDate={maxDate}
                selected={returnDetails.collectionDate}
                handleInputChange={handleReturnDetailsInputChange}
            />
            <span className="text-danger">{returnValidation.collectionDate.message}</span>
        </FormGroup>
    </Fragment>)
}

export default CreateReturnDetails;