import React from 'react';
import { Navbar, Nav, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';

import './HomeHeader.scss';

const HomeHeader = ({ isReturnsActive = true }) =>
    <div className="header-home-container">
        <Navbar collapseOnSelect expand="lg" className="header-home shadow-sm fixed-top bg-navbar">
            <Link to="/">
                <img src="Images/logo_home.svg"
                    height="48"
                    className="d-sm-block d-md-block d-lg-none d-xl-none"
                    alt="Return Robin" />
            </Link>
            <Link to="/">
                <img src="Images/logo_home.svg"
                    height="48"
                    className="d-none d-sm-none d-md-none d-lg-block d-xl-block ml-3"
                    alt="Return Robin" />
            </Link>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="ml-auto">
                    <Link className={`nav-link nav-first mr-2 ${isReturnsActive ? "active" : ""}`} to='/'><span>Returns</span></Link>
                    <Link className={`nav-link mr-2 ${!isReturnsActive ? "active" : ""}`} to='/help'><span>{"Help & Advice"}</span></Link>
                    <LinkContainer to='/register'>
                        <Button variant="primary" className="d-sm-block d-md-block d-lg-none d-xl-none mb-2" block>Sign Up</Button>
                    </LinkContainer>
                    <LinkContainer to='/register'>
                        <Button variant="primary" className="d-none d-sm-none d-md-none d-lg-block d-xl-block mx-3">Sign Up</Button>
                    </LinkContainer>
                    <LinkContainer to='/login'>
                        <Button variant="secondary" className="d-sm-block d-md-block d-lg-none d-xl-none" block>Sign In</Button>
                    </LinkContainer>
                    <LinkContainer to='/login'>
                        <Button variant="secondary" className="d-none d-sm-none d-md-none d-lg-block d-xl-block mr-3">Sign In</Button>
                    </LinkContainer>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    </div>

export default HomeHeader;