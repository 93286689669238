import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { withRouter } from 'react-router';
import { POST, GET, extractData } from '../../../Consumer';
import FormValidator from '../../common/FormValidator';
import Endpoints from '../../common/Endpoints';
import LoadingBar from '../../common/LoadingBar';
import { Header, SignInMessaging, SignInForm, CreateAccount } from '../shared/Snippets';

import '../shared/Login.scss';

class Login extends Component {

    constructor(props) {
        super(props);

        this.validator = new FormValidator([
            {
                field: 'email',
                method: 'isEmail',
                validWhen: true,
                message: 'Email is required.'
            },
            {
                field: 'password',
                method: 'isEmpty',
                validWhen: false,
                message: 'Password is required.'
            }
        ]);

        this.state = {
            form: {
                email: '',
                password: ''
            },
            passwordInputType: 'password',
            passwordInputIcon: faEye,
            validation: this.validator.valid(),
            warning: false,
            errorMessages: [],
            heading: '',
            signInRequired: false,
            loading: false
        };
    }

    validate = (form) => {
        return this.validator.validate(form);
    }

    handleShowHidePassword = () => {
        const { passwordInputType } = this.state;

        passwordInputType === "password" ?
            this.setState({ passwordInputType: 'text', passwordInputIcon: faEyeSlash }) :
            this.setState({ passwordInputType: 'password', passwordInputIcon: faEye });
    }

    handleInputChange = (e) => {
        let { name, value } = e.target;

        if (e.target.getAttribute('parent')) {
            let parent = e.target.getAttribute('parent');
            let parentValue = this.state.form[parent];
            parentValue[name] = value;

            name = parent;
            value = parentValue;
        }

        this.setState(prevState => ({
            ...prevState,
            form: {
                ...prevState.form,
                ...{ [name]: value }
            }
        }));
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        this.setState({ loading: true });
        const { form } = this.state;

        const validation = this.validate(form);
        this.setState({ validation: validation });
        if (validation.isValid) {
            return POST(Endpoints.RETAILER.POST.LOGIN, form)
                .then(response => response.json())
                .then((result) => {
                    var data = result.data;
                    if (result.error) result.data !== null ? this.setState({ errorMessages: result.data, loading: false }) : this.setState({ errorMessages: result.message, loading: false });
                    else if (data.user.active === false) this.setState({ errorMessages: ["Account has not yet been verified"], loading: false });
                    else {
                        if (data.setupComplete === false) {
                            this.props.onLogin(extractData(result), true);
                            (async () => {
                                await this.checkRetailer();
                                this.props.history.push({ pathname: "/retailer/address/addresscheck", state: { userid: data.user.id } });
                            })();
                        }
                        else {
                            this.props.onLogin(extractData(result), true);
                            (async () => {
                                await this.checkRetailer()
                                this.props.history.push({ pathname: "/" });
                            })();
                        }
                        this.setState({ loading: false });
                    }
                }).catch(() => this.setState({ errorMessages: ["There has been an issue logging in."], loading: false }));
        }
        this.setState({ loading: false })
    }

    checkRetailer = async () => {
        return GET(Endpoints.RETAILER.GET.CHECK_RETAILER)
            .then(response => response.json())
            .then(result => {
                !result.error ? localStorage.setItem('isRetailerVerified', result.data.setUp) : localStorage.setItem('isRetailerVerified', 'false');
            }).catch(() => localStorage.setItem('isRetailerVerified', 'false'));
    }


    render() {
        const { form, loading, validation, errorMessages, warning, signInRequired, passwordInputIcon, passwordInputType } = this.state;

        return (
            <React.Fragment>
                {loading && <LoadingBar />}
                <div className="center">
                    <Container>
                        <Header />
                        <div className="center-block">
                            <SignInMessaging errorMessages={errorMessages} warning={warning} signInRequired={signInRequired} />
                            <SignInForm form={form} handleSubmit={this.handleSubmit} validation={validation} handleInputChange={this.handleInputChange} passwordInputIcon={passwordInputIcon} passwordInputType={passwordInputType} handleShowHidePassword={this.handleShowHidePassword} forgotPasswordLink="/retailer/forgotpassword" />
                        </div>
                        <div className="full-width-separator my-5"></div>
                        <CreateAccount title="Register for Return Robin" text="Start using Return Robin" link="/retailer/register" buttonText="Register for Return Robin" />
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

export default withRouter(Login);
