import React, { Component, Fragment } from 'react';
import { Button, Row, Col, FormGroup, FormControl, FormLabel } from 'react-bootstrap';
import { GET, PUT } from '../../../Consumer';
import Endpoints from '../../common/Endpoints';
import SettingsNav from '../../common/nav/SettingsNav';
import Breadcrumb from '../../common/Breadcrumb';
import LoadingBar from '../../common/LoadingBar';
import FormValidator from '../../common/FormValidator';
import { RetailerSelectionCard, WarehouseSelectionCard } from '../../common/inputs/AddressSelectionCards';
import { SuccessAlert, ErrorAlert } from '../../common/Alerts';
import Confirm from '../../common/modals/Confirm'

const EditDetails = ({ apiKey, isConnected, selectedId, retailerAddress, selazarWarehouses, validation, handleApiKeyInputChange, handleWarehouseInputChange, handleSaveClick, handleShowHideDisconnectModal, handleShowHideConnectModal }) =>
    <Fragment>
        <section>
            <h4>Details</h4>
            <FormGroup>
                <FormLabel htmlFor="apiKeyField">API Key</FormLabel>
                <FormControl id="apiKeyField" className={validation.apiKey.isInvalid ? "input-error" : ""} type="text" name="apiKey" onChange={(e) => handleApiKeyInputChange(e.target.value)} value={apiKey} />
                <span className="text-danger">{validation.apiKey.message}</span>
            </FormGroup>
            <p className="title mb-2">Connection Status</p>
            <div>
                <span className={isConnected ? "status-completed" : "status-failed"}>{isConnected ? "Connected" : "Disconnected"}</span>
                {isConnected
                    ? <Button variant="link" className="ml-3 pt-0 pb-1 underline" onClick={handleShowHideDisconnectModal}>Disconnect</Button>
                    : <Button variant="link" className="ml-3 pt-0 pb-1 underline" onClick={handleShowHideConnectModal}>Connect</Button>
                }
            </div>
        </section>
        <section>
            <p className="title mt-4"> Warehouse Address</p>
        </section>
        <Row className="mt-4">
            <Col sm={12} md={6} className="mb-4">
                <RetailerSelectionCard selectedId={selectedId} address={retailerAddress} handleWarehouseInputChange={handleWarehouseInputChange} />
            </Col>
            {selazarWarehouses.length && selazarWarehouses.map(warehouse =>
                <Col key={`warehouse-${warehouse.id}`} sm={12} md={6} className="mb-4">
                    <WarehouseSelectionCard selectedId={selectedId} warehouse={warehouse} handleWarehouseInputChange={handleWarehouseInputChange} />
                </Col>
            )}
        </Row>
        <Row>
            <Col>
                <FormGroup className="mt-4">
                    <div className="float-right d-none d-sm-block">
                        <Button variant="primary" className="ml-2" onClick={handleSaveClick}>Save Changes</Button>
                    </div>
                    <div className="d-block d-sm-none text-center">
                        <Button variant="primary" block onClick={handleSaveClick}>Save Changes</Button>
                    </div>
                </FormGroup>
            </Col>
        </Row>
    </Fragment>

class EditIntegration extends Component {

    validator = new FormValidator([
        {
            field: 'apiKey',
            method: 'isEmpty',
            validWhen: false,
            message: 'Please enter your API key'
        },
    ]);

    state = {
        loading: true,
        integrationId: this.props.match.params.id || null,
        integration: {},
        retailerAddress: {},
        selazarWarehouses: [],
        validation: this.validator.valid(),
        loadError: false,
        saveError: false,
        saveSuccess: false,
        showDisconnectModal: false,
        showConnectModal: false
    };

    async componentDidMount() {
        await this.getIntegration();
    }

    getIntegration = async () => {
        const { integrationId } = this.state;
        return GET(Endpoints.SETTINGS.GET.INTEGRATION + integrationId)
            .then(response => response.json())
            .then(result => {
                const integration = result.data;
                const loadError = !integration;
                if (!loadError) {
                    (async () => {
                        await Promise.all([
                            this.getReturnRetailer(),
                            this.getSelazarWarehouses(integration.apiKey)
                        ])
                        this.setState({ integration: integration, loadError: false, loading: false });
                    })();
                } else {
                    this.setState({ integration: {}, loadError: true, loading: false });
                }
            });
    }

    getReturnRetailer = () =>
        GET(Endpoints.SETTINGS.GET.RETAILER_ADDRESS)
            .catch(error => console.log(error))
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(results => {
                const retailerAddress = results ? results.data[0] : {};
                const retailerAddressId = results ? results.data[0].id : "";
                this.setState(prevState => ({ retailerAddress: retailerAddress, integration: { ...prevState.integration, warehouseID: retailerAddressId } }));
            });

    getSelazarWarehouses = (apiKey) =>
        GET(Endpoints.SETTINGS.GET.SELAZAR_WAREHOUSES + apiKey)
            .catch(error => console.log(error))
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(results => {
                const selazarWarehouses = results ? results.data : [];
                this.setState({ selazarWarehouses: selazarWarehouses });
            });

    handleApiKeyInputChange = (value) => this.setState(prevState => ({ integration: { ...prevState.integration, apiKey: value } }));

    handleShowHideDisconnectModal = () => this.setState(prevState => ({ showDisconnectModal: !prevState.showDisconnectModal }));
    handleDisconnectIntegration = () => this.setState(prevState => ({ integration: { ...prevState.integration, isConnected: false }, showDisconnectModal: false }));

    handleShowHideConnectModal = () => this.setState(prevState => ({ showConnectModal: !prevState.showConnectModal }));
    handleConnectIntegration = () => this.setState(prevState => ({ integration: { ...prevState.integration, isConnected: true }, showConnectModal: false }));

    handleWarehouseInputChange = (useSelazarWarehouse, id) => this.setState(prevState => ({ integration: { ...prevState.integration, useSelazarWarehouse: useSelazarWarehouse, warehouseID: id } }));

    handleSaveClick = () => {
        const { integration } = this.state;
        this.setState({ loading: true });

        const validation = this.validator.validate(integration);

        if (validation.isValid) {
            return PUT(Endpoints.SETTINGS.PUT.INTEGRATION_SELAZAR, integration)
                .catch(error => console.log(error))
                .then(response => {
                    if (response.ok) return response.json();
                })
                .then(result => {
                    if (result && result.data) {
                        this.setState({ saveSuccess: true, saveError: false, validation: validation, loading: false });
                    } else this.setState({ saveSuccess: false, saveError: true, validation: validation, loading: false });
                });
        } else this.setState({ validation: validation, loading: false });
    }

    render() {
        const { loading, integration, retailerAddress, selazarWarehouses, validation, loadError, saveSuccess, saveError, showDisconnectModal, showConnectModal } = this.state;

        return (
            loading
                ? <LoadingBar />
                : <Fragment>
                    <SettingsNav activeTitle="Integrations" />
                    <div className="nav-content">
                        <div className="main-content">
                            <Row>
                                <Col sm={12} md={6}>
                                    <Breadcrumb link="/retailer/settings/integrations" text="Back to Integrations" />
                                    <h3 className="mb-3">Integrations</h3>
                                    {saveSuccess && <SuccessAlert heading="Successfully Updated" message="Integration successfully updated." />}
                                    {saveError && <ErrorAlert messages="Unable to update integration. Please ensure api key is valid and try again." />}
                                    {loadError
                                        ? <ErrorAlert messages="Unable to load integration. Please try again." />
                                        : <EditDetails
                                            apiKey={integration.apiKey}
                                            isConnected={integration.isConnected}
                                            selectedId={integration.warehouseID}
                                            retailerAddress={retailerAddress}
                                            selazarWarehouses={selazarWarehouses}
                                            validation={validation}
                                            handleApiKeyInputChange={this.handleApiKeyInputChange}
                                            handleWarehouseInputChange={this.handleWarehouseInputChange}
                                            handleSaveClick={this.handleSaveClick}
                                            handleShowHideDisconnectModal={this.handleShowHideDisconnectModal}
                                            handleShowHideConnectModal={this.handleShowHideConnectModal}
                                        />
                                    }
                                </Col>
                            </Row>
                            <Confirm
                                title="Disconnect App"
                                variant="outline-danger"
                                text="Are you sure you want to disconnect this integration? Once saved, connection will be lost to this app, and any new returns created will be sent to your default returns address."
                                handleClose={this.handleShowHideDisconnectModal}
                                handleConfirmAction={this.handleDisconnectIntegration}
                                buttonText="Disconnect Integration"
                                linkText="Continue Editing"
                                closeLink={true}
                                show={showDisconnectModal}
                            />
                            <Confirm
                                title="Connect App"
                                text="Are you sure you want to connect this integration? Once saved, connection will be updated."
                                handleClose={this.handleShowHideConnectModal}
                                handleConfirmAction={this.handleConnectIntegration}
                                buttonText="Connect Integration"
                                linkText="Continue Editing"
                                closeLink={true}
                                show={showConnectModal}
                            />
                        </div>
                    </div>
                </Fragment>
        )
    }
}

export default EditIntegration;
