import React from 'react';
import { Card } from 'react-bootstrap';
import ReturnSummary from './shared/ReturnSummary';

const ReturnCreatedAlert = () => (
    <Card className="card-success-custom mb-3">
        <Card.Title>Return Created Successfully</Card.Title>
        <Card.Body>
            <p>Your return has been created. All you need to do next is make sure your return item is packed safely and ready to go on your chosen collection date. Our driver brings your label with them and we handle the rest.</p>
        </Card.Body>
    </Card>
);

const CreateReturnConfirmation = ({ summary, showProducts }) =>
    <React.Fragment>
        <h4 className="mt-2 mb-3">Return Information</h4>
        <ReturnCreatedAlert showProducts={showProducts} />
        {showProducts && <p>Please package all returns in a single package.</p>}
        <ReturnSummary summary={summary} showParcelSummary={false} />
    </React.Fragment>

export default CreateReturnConfirmation;