import React from 'react';
import { Row, Col, Button, Card } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import Pagination from '../../../common/Pagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { getStatusClass } from '../../../../Utilities';
import LoadingBar from '../../../common/LoadingBar';
import { GET } from '../../../../Consumer';
import Endpoints from '../../../common/Endpoints';
import { NoReturns, ReturnsFilter, NoReturnsSearch } from '../../shared/Snippets';

import './Returns.scss';

const ReturnCard = ({ returnDetails }) => (
    <LinkContainer to={`/return/details/${returnDetails.returnID}`}>
        <Card className="card-nav p-3 mb-3">
            <p className="mb-0"><strong>{returnDetails.retailerName}, Order: {returnDetails.orderReference ? returnDetails.orderReference : returnDetails.orderID}</strong></p>
            <p className="mb-0">Created on {returnDetails.createdDateValue}</p>
            <p className="mb-2">Collection selected {returnDetails.preferredCollectionDateTimeValue}</p>
            <span className={`mr-auto ${getStatusClass(returnDetails.returnStatusValue)}`}>{returnDetails.returnStatusValue}</span>
            <FontAwesomeIcon className="card-icon" icon={faChevronRight} size="1x" />
        </Card>
    </LinkContainer>
);

const ReturnsList = ({ returns, onNext, onPrevious, onStart, onEnd, pageIndex, pageCount }) => (
    <Row>
        <Col sm={12} md={4}>
            {returns.map((returnDetails, i) => <ReturnCard key={`${i}-return`} returnDetails={returnDetails} />)}
            <Pagination onNext={onNext} onPrevious={onPrevious} onStart={onStart} onEnd={onEnd} pageIndex={pageIndex} pageCount={pageCount} />
        </Col>
    </Row>
);

class Returns extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            pageIndex: 1,
            pageCount: 1,
            returns: [],
            filter: {
                searchField: "",
                dateCreatedDescending: true,
                dateOfCollectionDescending: null,
                status: null
            },
            sort: "DateCreatedDesc",
            firstRender: true,
        };
    }

    async componentDidMount() {
        await this.getReturns();
    }

    getReturns = () => {
        const { pageIndex } = this.state;
        const filter = { ...this.state.filter };
        let url = new URL(Endpoints.RETURN.GET.RETURNS);

        Object.keys(filter).forEach(k => url.searchParams.append(k, filter[k]));
        url.searchParams.append("pageIndex", pageIndex);

        return GET(url)
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(results => {
                const returns = results ? results.data.results : [];
                const pageCount = results ? results.data.pageCount : 0;
                this.setState({ returns: returns, pageCount: pageCount, loading: false });
            })
            .catch(error => console.log(error));
    }

    handleCreateClick = (e) => {
        e.preventDefault();
        this.props.history.push('/return/create');
    }

    onNext = async () => {
        const { pageIndex, pageCount } = this.state;
        if (pageIndex < pageCount) {
            this.setState({ pageIndex: parseInt(pageIndex) + 1, loading: true, firstRender: false }, this.getReturns);
        }
    }

    onEnd = async () => {
        const { pageIndex, pageCount } = this.state;
        if (pageIndex < pageCount) {
            this.setState({ pageIndex: pageCount, loading: true, firstRender: false }, this.getReturns);
        }
    }

    onPrevious = async () => {
        const { pageIndex } = this.state;
        if (pageIndex > 1) {
            this.setState({ pageIndex: parseInt(pageIndex) - 1, loading: true, firstRender: false }, this.getReturns);
        }
    }

    onStart = async () => {
        const { pageIndex } = this.state;
        if (pageIndex > 1) {
            this.setState({ pageIndex: 1, loading: true, firstRender: false }, this.getReturns);
        }
    }

    onClear = async (e) => {
        e.preventDefault();
        this.setState({
            filter: { searchField: "", dateCreatedDescending: true, dateOfCollectionDescending: null, status: null, firstRender: false }, loading: true
        }, this.getReturns);
    }

    onSearch = async (e) => {
        e.preventDefault();
        this.setState({ loading: true, firstRender: false });
        await this.getReturns();
    }

    onFilterChange = (e) => {
        const { name, value } = e.target;

        if (name === "sort") {
            this.setState({ sort: value });

            if (value === "DateCreatedAsc") {
                this.setState(prevState => ({
                    filter: {
                        ...prevState.filter,
                        dateCreatedDescending: false,
                        dateOfCollectionDescending: null,
                    }
                }));
            } else if (value === "CollectionDateAsc") {
                this.setState(prevState => ({
                    filter: {
                        ...prevState.filter,
                        dateCreatedDescending: false,
                        dateOfCollectionDescending: false,
                    }
                }));
            } else if (value === "CollectionDateDesc") {
                this.setState(prevState => ({
                    filter: {
                        ...prevState.filter,
                        dateCreatedDescending: false,
                        dateOfCollectionDescending: true,
                    }
                }));
            } else {
                this.setState(prevState => ({
                    filter: {
                        ...prevState.filter,
                        dateCreatedDescending: true,
                        dateOfCollectionDescending: null,
                    }
                }));
            }
        } else {
            this.setState(prevState => ({
                filter: {
                    ...prevState.filter,
                    ...{ [name]: value }
                }
            }));
        }
    }

    render() {

        const { loading, returns, firstRender, filter, sort, pageIndex, pageCount } = this.state;
        const noReturns = returns.length === 0;
        const isRetailer = JSON.parse(localStorage.getItem('isRetailer'));

        return (
            <div className="main-content">
                {loading ? <LoadingBar /> :
                    <React.Fragment>
                        <h3 className="mb-0 pb-0">Returns</h3>
                        <Row className="mt-0 mb-2">
                            <Col sm={12} md={4} lg={2}>
                                <Button variant="secondary" onClick={this.handleCreateClick}>Create Return</Button>
                            </Col>
                        </Row>
                        <h4>Returns History</h4>
                        {(noReturns && firstRender)
                            ? <NoReturns />
                            : <React.Fragment>
                                <ReturnsFilter filter={filter} sort={sort} onFilterChange={this.onFilterChange} onClear={this.onClear} onSearch={this.onSearch} isRetailer={isRetailer} />
                                {noReturns
                                    ? <NoReturnsSearch />
                                    : <ReturnsList returns={returns} onNext={this.onNext} onPrevious={this.onPrevious} onStart={this.onStart} onEnd={this.onEnd} pageIndex={pageIndex} pageCount={pageCount} />}
                            </React.Fragment>}
                    </React.Fragment>}
            </div>
        );
    }
}

export default Returns;