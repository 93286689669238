import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import ReturnAddress from './ReturnAddress';
import { Row, Col } from 'react-bootstrap';

const AddressTitle = () =>
    <section>
        <h4>Address</h4>
        <p className="title">Select Address</p>
    </section>

const AddressSelection = ({ userAddresses, selectedAddressId, handleAddressSelection }) =>
    <Row>
        {userAddresses ? userAddresses.map(userAddress =>
            <Col sm={12} md={7} key={userAddress.id} className="px-2">
                <ReturnAddress address={userAddress.address} addressID={userAddress.id} selected={selectedAddressId === userAddress.id} handleAddressSelection={handleAddressSelection} />
            </Col>
        ) : null}
    </Row>

const AddressInfo = () =>
    <section>
        <p className="title">Want to add another address?</p>
        <p>Go to your <Link to={`/address/youraddresses`}>address book</Link> and add the new address before creating your return.</p>
    </section>

const CreateReturnAddress = ({ userAddresses, selectedAddressId, handleAddressSelection }) =>
    <Fragment>
        <AddressTitle />
        <AddressSelection userAddresses={userAddresses} selectedAddressId={selectedAddressId} handleAddressSelection={handleAddressSelection} />
        <AddressInfo />
    </Fragment>

export default CreateReturnAddress;