import React, { Component } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import LoadingBar from '../../common/LoadingBar';
import { Form, FormGroup, FormLabel, FormControl, Button } from 'react-bootstrap';
import { withRouter } from 'react-router';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { setCaretPosition, isEmpty } from '../../../Utilities';
import RRLogo from '../../common/RRLogo';
import { WarningAlert, ErrorAlert } from '../../common/Alerts';
import NewPassword from '../../common/inputs/NewPassword';
import Mobile from '../../common/Mobile';
import Confirmation from '../../common/Confirmation';
import FormValidator from '../../common/FormValidator';
import { STRONG_PASSWORD_PATTERN, NUMBER_PATTERN, SPECIAL_CHAR_PATTERN, LENGTH_PATTERN, CAPTCHA_KEY, PHONE_UTIL } from '../shared/Snippets';
import { POST } from '../../../Consumer';
import Endpoints from '../../common/Endpoints';

import '../shared/Register.scss';

const RegisterFrom = ({ registerDetails, validation, passwordInputType, passwordInputIcon, passwordChecks, recaptchaError, handleInputChange, handlePhoneNumberChange, handleShowHidePassword, handleRecaptcha, handleSubmit, confirmationValidation, handleConfirmationInputChange }) => (

    <Form>
        <FormGroup>
            <FormLabel htmlFor="companyNameField">Company Name</FormLabel>
            <FormControl id="companyNameField" className={validation.companyName.isInvalid ? "input-error" : undefined} name="companyName" maxLength="50" onChange={handleInputChange} value={registerDetails.companyName} />
            <span className="text-danger">{validation.companyName.message}</span>
        </FormGroup>
        <FormGroup>
            <FormLabel htmlFor="forenameField">Forename</FormLabel>
            <FormControl id="forenameField" className={validation.forename.isInvalid ? "input-error" : undefined} name="forename" maxLength="50" onChange={handleInputChange} value={registerDetails.forename} />
            <span className="text-danger">{validation.forename.message}</span>
        </FormGroup>
        <FormGroup>
            <FormLabel htmlFor="surnameField">Surname</FormLabel>
            <FormControl id="surnameField" className={validation.surname.isInvalid ? "input-error" : undefined} name="surname" maxLength="50" onChange={handleInputChange} value={registerDetails.surname} />
            <span className="text-danger">{validation.surname.message}</span>
        </FormGroup>
        <FormGroup>
            <FormLabel htmlFor="emailField">Email</FormLabel>
            <FormControl id="emailField" className={validation.email.isInvalid ? "input-error" : undefined} type="email" name="email" maxLength="50" onChange={handleInputChange} value={registerDetails.email} />
            <span className="text-danger">{validation.email.message}</span>
        </FormGroup>

        <Mobile value={registerDetails.phoneNumber} invalidPhoneNumber={validation.phoneNumber.isInvalid} handlePhoneNumberChange={handlePhoneNumberChange} validationMessage={validation.phoneNumber.message} />

        <NewPassword passwordStatus={validation.password.isInvalid ? "input-error" : undefined}
            passwordInputType={passwordInputType}
            formPassword={registerDetails.password}
            handleInputChange={handleInputChange}
            handleShowHidePassword={handleShowHidePassword}
            passwordInputIcon={passwordInputIcon}
            validationPasswordMessage={validation.password.message}
            passwordChecks={passwordChecks}
        />

        <Confirmation
            confirmation={registerDetails.confirmation}
            confirmationValidation={confirmationValidation}
            handleConfirmationInputChange={handleConfirmationInputChange}
        />

        <div className="center mt-4">
            <ReCAPTCHA sitekey={CAPTCHA_KEY} onChange={handleRecaptcha} />
        </div>
        {recaptchaError && <p className="center text-danger">Please complete captcha</p>}

        <FormGroup className="mt-4">
            <Button variant="primary" block onClick={handleSubmit}>Register</Button>
        </FormGroup>
    </Form>
);

class Register extends Component {

    constructor(props) {
        super(props);

        this.validator = new FormValidator([
            {
                field: 'companyName',
                method: 'isEmpty',
                validWhen: false,
                message: 'Enter the company name'
            },
            {
                field: 'forename',
                method: 'isEmpty',
                validWhen: false,
                message: 'Enter your forename'
            },
            {
                field: 'surname',
                method: 'isEmpty',
                validWhen: false,
                message: 'Enter your surname'
            },
            {
                field: 'email',
                method: 'isEmail',
                validWhen: true,
                message: 'This is not a valid email address, try again'
            },
            {
                field: 'phoneNumber',
                method: 'isEmpty',
                validWhen: false,
                message: 'This is not a valid phone number, try again'
            },
            {
                field: 'password',
                method: 'matches',
                args: [STRONG_PASSWORD_PATTERN],
                validWhen: true,
                message: 'Password does not meet the criteria, try again'
            }
        ]);

        this.state = {
            registerDetails: {
                companyName: '',
                forename: '',
                surname: '',
                email: '',
                phoneNumber: '',
                countryCode: '',
                password: '',
                confirmation: false
            },
            recaptcha: false,
            recaptchError: false,
            passwordInputType: 'password',
            passwordInputIcon: faEye,
            passwordChecks: {
                passwordNumberCheck: false,
                passwordSpecialCharCheck: false,
                passwordLengthCheck: false
            },
            validation: this.validator.valid(),
            warning: false,
            errorMessages: [],
            heading: '',
            recaptchaError: false,
            submitted: false,
            confirmationValidation: true,
        };
    }

    validate = () => {
        const { registerDetails } = this.state;
        let validation = this.validator.validate(registerDetails);
        
        if (!isEmpty(registerDetails.phoneNumber)) {
            try {
                const phone = PHONE_UTIL.parse(registerDetails.phoneNumber, registerDetails.countryCode);
                if (!PHONE_UTIL.isValidNumber(phone)) {
                    validation.isValid = false;
                    validation.phoneNumber.isInvalid = true;
                    validation.phoneNumber.message = "This is not a valid phone number, try again.";
                }
            } catch (e) {
                console.log(e);
                validation.isValid = false;
                validation.phoneNumber.isInvalid = true;
                validation.phoneNumber.message = "This is not a valid phone number, try again.";
            }
        }
        return validation;
    }

    handleShowHidePassword = () => {
        const { passwordInputType } = this.state;

        passwordInputType === "password" ?
            this.setState({ passwordInputType: 'text', passwordInputIcon: faEyeSlash }) :
            this.setState({ passwordInputType: 'password', passwordInputIcon: faEye });
    }

    handleRecaptcha = () => {
        this.setState({ recaptcha: true });
    }

    handleConfirmationInputChange = () => {
        this.setState(prevState => ({
            ...prevState,
            registerDetails: {
                ...prevState.registerDetails,
                confirmation: !prevState.registerDetails.confirmation
            }
        }));
    }

    handleInputChange = (e) => {
        let { name, value } = e.target;

        if (name === "password") {
            this.setState({
                passwordChecks: {
                    passwordNumberCheck: NUMBER_PATTERN.test(value),
                    passwordSpecialCharCheck: SPECIAL_CHAR_PATTERN.test(value),
                    passwordLengthCheck: LENGTH_PATTERN.test(value)
                }
            });
        }

        if (e.target.getAttribute('parent')) {
            let parent = e.target.getAttribute('parent');
            let parentValue = this.state.registerDetails[parent];
            parentValue[name] = value;

            name = parent;
            value = parentValue;
        }

        this.setState(prevState => ({
            ...prevState,
            registerDetails: {
                ...prevState.registerDetails,
                [name]: value
            }
        }));
    }

    handlePhoneNumberChange = (...args) => {
        setCaretPosition(args[3].length);

        this.setState(prevState => ({
            ...prevState,
            registerDetails: {
                ...prevState.registerDetails,
                phoneNumber: args[3],
                countryCode: args[2].iso2
            }
        }));
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({ loading: true });
        const { recaptcha, registerDetails, confirmationValidation } = this.state;
        const validation = this.validate();
        const emailAddress = registerDetails.email;
        this.setState({ validation: validation, recaptchaError: !recaptcha, submitted: true });

        this.setState({ confirmationValidation: registerDetails.confirmation });

        if (recaptcha && validation.isValid && confirmationValidation) {
            return POST(Endpoints.RETAILER.POST.REGISTER, registerDetails)
                .then(response => response.json())
                .then((result) => {
                    if (result.error) {
                        if (result.data !== null) this.setState({ warning: result.data.warning ?? false, heading: result.data.heading, signInRequired: result.data.signInRequired ?? false, errorMessages: result.data.errorMessages ?? [], loading: false });
                        else this.setState({ errorMessages: result.message, loading: false });
                    }
                    else this.props.history.push({ pathname: "/retailer/register/accountverification", state: { emailAddress: emailAddress } });
                })
                .catch(() => this.props.history.push({ pathname: "/retailer/register/issue" }));
        }
        this.setState({ loading: false });
    }

    render() {
        const { registerDetails, loading, errorMessages, signInRequired, heading, validation, passwordInputType, passwordInputIcon, passwordChecks, recaptchaError, submitted, confirmationValidation } = this.state;

        return (
            <React.Fragment>
                {loading && <LoadingBar />}
                <div className="center">
                    <div className="container">
                        <RRLogo />
                        <div className="center-block mb-5">
                            <h3>Register</h3>
                            <p>Enter your details to create a Return Robin retailer account.</p>
                            {errorMessages.length > 0 && <ErrorAlert messages={errorMessages} heading={heading} />}
                            {signInRequired && <WarningAlert linkRequired={signInRequired} link="retailer/login" linkPreText="An account has already been registered with this email address." linkText="Sign in" linkPostText="to use Return Robin." />}

                            <RegisterFrom
                                registerDetails={registerDetails}
                                validation={validation}
                                passwordInputType={passwordInputType}
                                passwordInputIcon={passwordInputIcon}
                                passwordChecks={passwordChecks}
                                recaptchaError={recaptchaError}
                                handleInputChange={this.handleInputChange}
                                handlePhoneNumberChange={this.handlePhoneNumberChange}
                                handleShowHidePassword={this.handleShowHidePassword}
                                handleConfirmationInputChange={this.handleConfirmationInputChange}
                                confirmationValidation={confirmationValidation}
                                handleRecaptcha={this.handleRecaptcha}
                                handleSubmit={this.handleSubmit}
                                submitted={submitted}
                            />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default withRouter(Register);