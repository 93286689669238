import React, { Fragment } from 'react';
import { Row, Col, Button, InputGroup, FormControl } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHistory, faSearch } from '@fortawesome/free-solid-svg-icons';
import { formatDate, getStatusClass } from '../../../Utilities';
import { WarningAlert, SuccessAlert, ErrorAlert } from '../../common/Alerts';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

const NoReturns = () => (
    <div className="message-empty">
        <FontAwesomeIcon icon={faHistory} size="8x" />
        <div className="message">
            <p>You have not created any returns yet. When you create a return you can find the details here.</p>
        </div>
    </div>
);

const NoReturnsSearch = () => (
    <Row>
        <Col sm={12} md={6}>
            <WarningAlert message="There are no results matching your search, check that you entered the details correctly and try again." />
        </Col>
    </Row>
);

const ReturnsFilter = ({ filter, sort, onFilterChange, onSearch, onClear, isRetailer }) => (
    <React.Fragment>
        <Row className="form-group-filter">
            <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 4 }}>
                <InputGroup >
                    <FormControl id="searchField" type="text" name="searchField" placeholder={isRetailer ? 'Search Order Number or Return Robin ID' :'Search Order Number or Retailer'} value={filter.searchField} onChange={onFilterChange} />
                    <InputGroup.Append>
                        <InputGroup.Text id="searchBox" className="search-icon">
                            <FontAwesomeIcon icon={faSearch} />
                        </InputGroup.Text>
                    </InputGroup.Append>
                </InputGroup>
            </Col>
            <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 4 }}>
                <FormControl as="select" name="sort" value={sort} onChange={onFilterChange}>
                    <option value="DateCreatedDesc">Date created - newest to oldest</option>
                    <option value="DateCreatedAsc">Date created - oldest to newest</option>
                    <option value="CollectionDateDesc">Collection date - newest to oldest</option>
                    <option value="CollectionDateAsc">Collection date - oldest to newest</option>
                </FormControl>
            </Col>
            <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 4 }}>
                <FormControl as="select" name="status" value={filter.status} onChange={onFilterChange}>
                    <option value="">Filter</option>
                    <option value="Pending">Pending</option>
                    <option value="Completed">Completed</option>
                    <option value="InProcess">In Process</option>
                    <option value="FailedCollection">Failed Collection</option>
                    <option value="RetailerRejected">Retailer Rejected</option>
                    <option value="Failed">Failed</option>
                    <option value="Cancelled">Cancelled</option>
                </FormControl>
            </Col>
        </Row>
        <div className="button-group-filter mb-3 d-none d-md-block">
            <Button variant="primary" className="float-right ml-3" onClick={onSearch}>Apply Filters</Button>
            <Button variant="secondary" className="float-right" onClick={onClear}>Clear Filters</Button>
        </div>
        <Row className="mb-3 d-md-none d-lg-none d-xl-none">
            <Col xs={12} sm={12}>
                <Button variant="primary" className="mb-0" onClick={onSearch}>Apply Filters</Button>
            </Col>
            <Col xs={12} sm={12}>
                <Button variant="secondary" onClick={onClear}>Clear Filters</Button>
            </Col>
        </Row>
    </React.Fragment>
);

const OrderDetails = ({ emailAddress, orderReference, orderID, orderDate }) => (
    <section>
        <h4>Order Details</h4>
        {emailAddress && <Fragment>
            <p className="title">Email Address</p>
            <p>{emailAddress}</p>
        </Fragment>}
        {orderReference
            ? <Fragment>
                <p className="title">Order Reference</p>
                <p>{orderReference}</p>
            </Fragment>
            : <Fragment>
                <p className="title">Order ID</p>
                <p>{orderID}</p>
                <p className="title">Order Date</p>
                <p>{formatDate(orderDate)}</p>
            </Fragment>
        }
    </section>
);

const ProductDetails = ({ products, returnReason, otherReason, otherReasonInformation, additionalInformation }) => (
    <section>
        <h4>Product Details</h4>
        {(products && products.length > 0) && <div className="mb-3">
            <p className="title">Items</p>
            {products.map(product => <p key={product.id} className="mb-0">{product.name} {product.skuCode && <span>- {product.skuCode}</span>}</p>)}
        </div>
        }
        <p className="title">Return Reason</p>
        <p>{returnReason}</p>
        {otherReason && <Fragment>
            <p className="title">Other Reason</p>
            <p>{otherReasonInformation}</p>
        </Fragment>}
        {additionalInformation && <Fragment>
            <p className="title">Additional Information</p>
            <p>{additionalInformation}</p>
        </Fragment>}
    </section>
);

const Details = ({ collectionDate, returnRobinId, createdDate, courier, status, trackingReference, postcode }) => (
    <section>
        <h4>Return Details</h4>
        <p className="title">Collection Date</p>
        <p>{formatDate(collectionDate)}</p>
        <p className="title">Return Robin ID</p>
        <p>{returnRobinId}</p>
        <p className="title">Date Created</p>
        <p>{formatDate(createdDate)}</p>
        <p className="title">Courier</p>
        <p>{courier}</p>
        {
            trackingReference 
            ? (status === "Cancelled" 
                ? <Fragment>
                    <p className="title mb-1 mt-3">Tracking Number</p>
                    <p>{trackingReference}</p>
                    </Fragment>
                : <Fragment>
                    <p className="title mb-1 mt-3">Tracking Number</p>
                    <a
                        href={`https://www.yodel.co.uk/track/${trackingReference}?postcode=${postcode}`}
                        target="_blank"
                        rel="noopener noreferrer">
                        {trackingReference}
                    </a>
                    </Fragment>) 
            : ""
        }
        <p className="title mb-1 mt-3">Status</p>
        <span className={getStatusClass(status)}>{status}</span>
    </section>
);

const ChangeHistory = ({ changeHistory, isRetailer, close, retailer, orderNumber }) => (

    <div className="main-content return-details">
        <div className="link-breadcrumb mb-4">
            <FontAwesomeIcon icon={faChevronLeft} size="xs" /><Button variant="link" className="p-0 underline" onClick={close}>Back to Return Details</Button>
        </div>

        <h3 className="mt-2 mb-3">Return Status History</h3>
        <Fragment>
            <p>Below you can find the status history for {isRetailer ? "the" : "your "}<span className="font-weight-bold">{retailer}</span> order <span className="font-weight-bold">{orderNumber}</span></p></Fragment>

        <hr />
        {changeHistory.map((change, i) =>
            change.event === 'PreferredCollectionDateChanged' ?
                <Fragment>
                    <p className="font-weight-bold small" key={`${i}-change`}>Collection Date Changed</p>
                    <p className="mt-1">Original collection date: {change.previousCollectionDate}</p>
                    <p>New collection date: {change.newCollectionDate}</p>
                    <hr />
                </Fragment> : change.event === 'StatusChange' ?
                    <Fragment>
                        <p className="font-weight-bold small mb-1" key={`${i}-change`}>Status Update</p>
                        <span className={getStatusClass(change.newStatus)}>{change.newStatusValue}</span>
                        <p className="mt-1">{change.createdDate}</p>
                        <hr />
                    </Fragment> : <Fragment>No changes recorded</Fragment>
        )}
    </div>
);

const SuccessMessage = ({ heading, message }) => (
    <Row>
        <Col sm={12} md={6} >
            <SuccessAlert heading={heading} message={message} />
        </Col>
    </Row>
);

const ErrorMessage = ({ heading, message }) => (
    <Row>
        <Col sm={12} md={6} >
            <ErrorAlert heading={heading} messages={message} />
        </Col>
    </Row>
);

export {
    NoReturns,
    NoReturnsSearch,
    ReturnsFilter,
    OrderDetails,
    ProductDetails,
    Details,
    ChangeHistory,
    SuccessMessage,
    ErrorMessage
}