import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { ErrorAlert } from './Alerts';
import { useHistory } from 'react-router';

const VERIFICATION_TITLE = "Verification Pending";
const VERIFICATION_MESSAGE = "You can add an integration to a fulfilment partner to potentially speed up the verification."

const UnverifiedRetailer = ({ title }) => {
    
    const { push } = useHistory();

    return (<div className="main-content">
        <h3>{title ? title : "Welcome to Return Robin!"}</h3>
        <Row>
            <Col sm={12} md={6}>
                <ErrorAlert heading={VERIFICATION_TITLE} messages={VERIFICATION_MESSAGE} />
                <Button variant="primary" onClick={() => push('/retailer/settings/integrations')}>Go to Integrations</Button>
            </Col>
        </Row>
    </div>)
}

export default UnverifiedRetailer;
