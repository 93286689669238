import React, { Component, Fragment } from 'react';
import { Row, Col, FormGroup, FormControl, FormLabel, Button } from 'react-bootstrap';
import { GET, PUT } from '../../Consumer';
import Endpoints from '../common/Endpoints';
import Slider from '../common/inputs/Slider';
import FormValidator from '../common/FormValidator';
import { ErrorAlert, SuccessAlert } from '../common/Alerts';
import SettingsNav from '../common/nav/SettingsNav';
import LoadingBar from '../common/LoadingBar';

const setOrderAgeLimit = (orderAgeLimit) => ({
    active: orderAgeLimit.retailerRate,
    selection: {
        thirtyDays: orderAgeLimit.retailerRateValue === 30 || !orderAgeLimit.retailerRate,
        sixtyDays: orderAgeLimit.retailerRateValue === 60,
        custom: orderAgeLimit.retailerRateValue !== 30 && orderAgeLimit.retailerRateValue !== 60 && orderAgeLimit.retailerRate
    },
    customDays: (orderAgeLimit.retailerRateValue !== 30 && orderAgeLimit.retailerRateValue !== 60 && orderAgeLimit.retailerRate) ? orderAgeLimit.retailerRateValue : 0
});

const AgeLimitSetting = ({ active, selection, customDays, validation, handleInputChange, handleSelectionChange, handleSaveClick }) =>
    <Fragment>
        <div className="form-input-description mb-3">
            <p>Customers will not be allowed to create a return for an order older than your selection</p>
        </div>
        <Row>
            <Col sm={12} md={6}>
                <Slider id="ageLimit" name="ageLimit" label="Turn on order age limit" checked={active} onChange={() => handleInputChange("active", !active)} />
            </Col>
        </Row>
        {active &&
            <Fragment>
                <FormGroup>
                    <FormGroup className="custom-control custom-radio mb-0">
                        <FormControl className="custom-control-input" type="radio" id="radio-thirty" name="thirtyDays" value={selection.thirtyDays} checked={selection.thirtyDays} onChange={() => handleSelectionChange("thirtyDays")} />
                        <FormLabel className="custom-control-label font-weight-normal" htmlFor="radio-thirty">30 Days</FormLabel>
                    </FormGroup>
                    <FormGroup className="custom-control custom-radio mb-0">
                        <FormControl className="custom-control-input" type="radio" id="radio-sixtyDays" name="sixtyDays" value={selection.sixtyDays} checked={selection.sixtyDays} onChange={() => handleSelectionChange("sixtyDays")} />
                        <FormLabel className="custom-control-label font-weight-normal" htmlFor="radio-sixtyDays">60 Days</FormLabel>
                    </FormGroup>
                    <FormGroup className="custom-control custom-radio mb-0">
                        <FormControl className="custom-control-input" type="radio" id="radio-custom" name="custom" value={selection.custom} checked={selection.custom} onChange={() => handleSelectionChange("custom")} />
                        <FormLabel className="custom-control-label font-weight-normal" htmlFor="radio-custom">Custom</FormLabel>
                    </FormGroup>
                </FormGroup>
                {selection.custom &&
                    <div className="mb-2">
                        <Row>
                            <Col sm={12} md={3}>
                                <FormGroup className="mb-0">
                                    <FormControl id="customDaysField" className={validation.customDays.isInvalid ? "input-error" : ""} type="number" min="1" max="1000" name="customDays" onChange={(e) => handleInputChange("customDays", e.target.value)} value={customDays} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <div>
                            <span>Enter number in days e.g.14</span>
                        </div>
                        <span className="text-danger">{validation.customDays.message}</span>
                    </div>
                }
            </Fragment>
        }
        <FormGroup className="mt-4">
            <div className="float-right d-none d-sm-block">
                <Button variant="primary" className="ml-2" onClick={handleSaveClick}>Save</Button>
            </div>
            <div className="d-block d-sm-none text-center">
                <Button variant="primary" block onClick={handleSaveClick}>Save</Button>
            </div>
        </FormGroup>
    </Fragment>

class OrderAgeLimit extends Component {

    validator = new FormValidator([
        {
            field: 'customDays',
            method: 'isInt',
            validWhen: true,
            args: [{ min: 1, max: 1000 }],
            message: 'Please enter your custom days between 1 and 1000'
        },
    ]);

    state = {
        loading: false,
        ageLimit: {
            active: false,
            selection: {
                thirtyDays: false,
                sixtyDays: false,
                custom: false
            },
            customDays: 0
        },
        validation: this.validator.valid(),
        showSuccess: false,
        showLoadError: false,
        showSaveError: false
    };

    async componentDidMount() {
        await this.getOrderAgeLimit();
        this.setState({ loading: false });
    }

    getOrderAgeLimit = () =>
        GET(Endpoints.SETTINGS.GET.ORDER_AGE)
            .catch(error => console.log(error))
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(results => {
                const orderAgeLimit = results ? results.data : null;

                if (orderAgeLimit) {
                    const ageLimit = setOrderAgeLimit(orderAgeLimit);
                    this.setState({ ageLimit: ageLimit, showLoadError: false });
                } else this.setState({ showLoadError: true });
            });

    handleInputChange = (name, value) => this.setState(prevState => ({ ageLimit: { ...prevState.ageLimit, [name]: value } }));

    handleSelectionChange = (name) => {
        const noSelection = {
            thirtyDays: false,
            sixtyDays: false,
            custom: false
        }

        this.setState(prevState => ({ ageLimit: { ...prevState.ageLimit, selection: { ...noSelection, [name]: true } } }));
    }

    handleSaveClick = () => {
        const { ageLimit } = this.state;
        this.setState({ loading: true });

        if (ageLimit.selection.custom) {
            const validation = this.validator.validate(ageLimit);
            this.setState({ validation: validation });

            if (!validation.isValid) {
                this.setState({ loading: false, showSuccess: false, showSaveError: false });
                return;
            }
        }

        const orderAgeLimit = {
            retailerRate: ageLimit.active,
            retailerRateValue: !ageLimit.active
                ? 0
                : ageLimit.selection.custom
                    ? ageLimit.customDays
                    : ageLimit.selection.thirtyDays
                        ? 30
                        : 60
        }

        return PUT(Endpoints.SETTINGS.PUT.ORDER_AGE, orderAgeLimit)
            .catch(error => console.log(error))
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(results => {
                const orderAgeLimit = results ? results.data : null;

                if (orderAgeLimit) {
                    const ageLimit = setOrderAgeLimit(orderAgeLimit);
                    this.setState({ ageLimit: ageLimit, showSaveError: false, showSuccess: true, loading: false });
                } else this.setState({ showSaveError: true, showSuccess: false, loading: false });
            });
    }

    render() {
        const { loading, ageLimit, validation, showLoadError, showSaveError, showSuccess } = this.state;

        return (
            loading
                ? <LoadingBar />
                : <Fragment>
                    <SettingsNav activeTitle="Return Settings" />
                    <div className="nav-content">
                        <div className="main-content">
                            <Row>
                                <Col sm={12} md={6}>
                                    <h3 className="mb-3">Order Age Limit</h3>
                                    {showSuccess && <SuccessAlert message="Successfully update order age limit." />}
                                    {showSaveError && <ErrorAlert messages="Unable to update order age limit. Please try again." />}
                                    {showLoadError
                                        ? <ErrorAlert messages="Unable to retrieve order age limit. Please try again." />
                                        : <AgeLimitSetting
                                            active={ageLimit.active}
                                            selection={ageLimit.selection}
                                            customDays={ageLimit.customDays}
                                            validation={validation}
                                            handleInputChange={this.handleInputChange}
                                            handleSelectionChange={this.handleSelectionChange}
                                            handleSaveClick={this.handleSaveClick}
                                        />
                                    }
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Fragment>
        )
    }
}

export default OrderAgeLimit;
