import React, { Fragment, useEffect, useState } from 'react';
import LoadingBar from '../../../common/LoadingBar';
import SettingsNav from '../../../common/nav/SettingsNav';
import SubNav from '../../../common/nav/SubNav';
import UnverifiedRetailer from '../../../common/UnverifiedRetailer';
import { PAYMENTS_SUB_NAV, PAYMENTS_SUB_NO_DD_NAV } from '../../../common/constants';
import Endpoints from '../../../common/Endpoints';
import { GET_ASYNC, DELETE } from '../../../../Consumer';
import AddCard from './AddCard';
import CompleteCard from './CompleteCard';
import CardForm from './CardForm';
import CardDetails from './CardDetails';
import { useHistory } from 'react-router';
import { WarningAlert } from '../../../common/Alerts';

const CardPayment = ({ location }) => {

    //#region State
    const history = useHistory();

    const [showError, setShowError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [cardDetails, setCardDetails] = useState(null);
    const [showForm, setShowForm] = useState(false);
    const [showRemoveModal, setShowRemoveModal] = useState(false);
    const [showCompleteError] = useState(location?.state?.showCompleteError || false);
    const [completeErrorMessage] = useState(location?.state?.completeErrorMessage || undefined);

    const [isRetailer, setIsRetailer] = useState(false);
    const [retailerVerified, setRetailerVerified] = useState(null);
    const [hasIntegration, setHasIntegration] = useState(null);

    const [showVerification, setShowVerification] = useState(true);
    const [enabledForDirectDebit, setEnabledForDirectDebit] = useState(false);

    //#endregion

    //#region API

    useEffect(() => {
        (async () => {
            try {
                const response = await GET_ASYNC(Endpoints.RETAILER.GET.IS_RETAILER);

                if (response?.ok) {
                    const data = await response.json();
                    if (data.data === true) {
                        setIsRetailer(true);
                    }
                    else {
                        localStorage.clear();
                        history.push('/login');
                    }
                }
            } catch (error) {
                console.log(error);
                setLoading(false);
                setShowError(true);
            }
        })();
    }, [setIsRetailer, history]);

    useEffect(() => {
        if (isRetailer) {
            (async () => {
                try {
                    const response = await GET_ASYNC(Endpoints.RETAILER.GET.CHECK_RETAILER);

                    if (response?.ok) {
                        const data = await response.json();
                        if (data.data.setUp === true) {
                            setRetailerVerified(true);
                        } else {
                            setRetailerVerified(false);
                        }
                    }
                } catch (error) {
                    console.log(error);
                    setLoading(false);
                    setShowError(true);
                }
            })();
        }
    }, [isRetailer, setRetailerVerified]);

    useEffect(() => {
        if (retailerVerified != null) {
            (async () => {
                try {
                    const response = await GET_ASYNC(Endpoints.RETAILER.GET.HAS_INTEGRATION);

                    if (response?.ok) {
                        const data = await response.json();
                        setHasIntegration(data.data);
                    }
                } catch (error) {
                    console.log(error);
                    setLoading(false);
                    setShowError(true);
                } finally {
                    setLoading(false);
                }
            })();

        }
    }, [retailerVerified]);

    useEffect(() => {
        if (isRetailer) {
            getLatestCardDetails();
            checkForDirectDebit();
        }
    }, [isRetailer]);

    /**
     * Check to see if we show UnverifiedRetailer or CardDetails.
     *  unverified retailer with integration should see Card details page,
     *  unverified retailer without integration should see unverified retailer page,
     *  verified retailer with or without integration should card details page
     */
    useEffect(() => { setShowVerification(() => !(retailerVerified || hasIntegration)); }, [retailerVerified, hasIntegration]);

    const getLatestCardDetails = async () => {
        try {
            const response = await GET_ASYNC(Endpoints.FINANCE.CARD.GET.CURRENT);

            if (response?.ok) {
                const data = await response.json();
                if (data.data != null) setCardDetails({ name: data.data.name, cardSummary: data.data.cardNumber, active: data.data.active });
                else setCardDetails(null);
            }
        } catch (error) {
            console.log(error);
            setLoading(false);
            setShowError(true);
        }
    }

    const onRemoveCardAction = async () => {
        try {
            const response = await DELETE(Endpoints.FINANCE.CARD.DELETE.REMOVE);

            if (response?.ok) {
                const data = await response.json();
                if (data != null) setCardDetails(null)
            }
        } catch (error) {
            console.log(error);
            setLoading(false);
            setShowError(true);
        }
    }

    const checkForDirectDebit = async () => {
        try {
            const response = await GET_ASYNC(Endpoints.FINANCE.DIRECT_DEBIT.GET.ENABLED);

            if (response?.ok) {
                const data = await response.json();
                if (data != null) {
                    setEnabledForDirectDebit(data.data)
                }
            }
        } catch (error) {
            console.log(error);
            setLoading(false);
            setShowError(true);
        }
    }


    //#endregion

    //#region Controls

    const onAddCardClick = () => setShowForm(true);
    const onReplaceCardClick = () => setShowForm(true);
    const hideShowRemoveModal = () => setShowRemoveModal(!showRemoveModal);

    //#endregion

    //#region Render

    if (showError) {
        return (
            <>
                <SettingsNav activeTitle="Payments" />
                <div className="nav-content">
                    <div className="main-content mt-2">
                        <WarningAlert linkRequired="true" link="retailer/settings/payments" linkPreText="An API error has occurred, please try again." linkText="Reload" />
                    </div>
                </div>
            </>
        );
    }

    return (
        loading
            ? <LoadingBar />
            : <Fragment>
                <SettingsNav activeTitle="Payments" />
                <div className="nav-content">
                    {showVerification
                        ? <UnverifiedRetailer history={history} />
                        : <Fragment>
                            <SubNav title="Payments" subNavList={enabledForDirectDebit ? PAYMENTS_SUB_NAV : PAYMENTS_SUB_NO_DD_NAV} activeKey="Card Payment" />
                            <div className="main-content mt-2">
                                {showCompleteError
                                    ? <CompleteCard errorMessages={completeErrorMessage} />
                                    : (cardDetails && !showForm)
                                        ? <CardDetails
                                            details={cardDetails}
                                            showRemoveModal={showRemoveModal}
                                            hideShowRemoveModal={hideShowRemoveModal}
                                            onRemoveCardAction={onRemoveCardAction}
                                            onReplaceCardClick={onReplaceCardClick}
                                        />
                                        : showForm
                                            ? <CardForm />
                                            : <AddCard onAddCardClick={onAddCardClick} />
                                }
                            </div>
                        </Fragment>
                    }
                </div>
            </Fragment>
    );

    //#endregion
}

export default CardPayment;