import React, { Component, Fragment } from 'react';
import { Row, Col, FormControl, InputGroup } from 'react-bootstrap';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GET } from '../../../../Consumer';
import Endpoints from '../../../common/Endpoints';
import LoadingBar from '../../../common/LoadingBar';
import { WarningAlert, ErrorAlert } from '../../../common/Alerts';

const RetailerCard = ({ retailer, onCardClick, selected }) =>
    <Col xs={6} md={4} lg={3}>
        <div className="mb-2 retailer-card" key={`card-${retailer.id}`}>
            <label className={selected ? "retailer-card-selected mr-2" : "mr-2"}>
                <input key={`input-${retailer.id}`} id={retailer.id} value={retailer.id} type="checkbox" className="retailerCheckBox" name={retailer.name} onChange={onCardClick} checked={selected} />
                <span>{retailer.name}</span>
            </label>
        </div>
    </Col>

class CreateReturnRetailerDetails extends Component {

    state = {
        loading: true,
        allRetailers: [],
        errorMessages: [],
        filteredRetailers: [],
        searchFilter: '',
        selectedRetailerID: ''
    };

    async componentDidMount() {
        await this.fetchRetailers();
        this.setState({ loading: false });
    }

    fetchRetailers = async () =>
        GET(Endpoints.RETAILER.GET.RETRIEVE_RETAILERS)
            .then(response => response.json())
            .then((result) => {
                if (result.error) {
                    result.data !== null
                        ? this.setState({ errorMessages: result.data })
                        : this.setState({ errorMessages: result.message });
                } else {
                    const retailers = result.data
                        ? result.data
                        : [];
                    this.setState({ allRetailers: retailers, filteredRetailers: retailers });
                }
            })
            .catch(() => this.setState({ errorMessages: ["There has been an issue retrieving retailers."] }));

    onFilterChange = (value) => {
        const { allRetailers } = this.state;
        this.setState({ searchFilter: value, filteredRetailers: allRetailers.filter(e => e.name.toLowerCase().includes(value.toLowerCase())) });
    }

    render() {
        const { filteredRetailers, errorMessages, searchFilter, loading } = this.state;
        const { handleRetailerSelection, retailerDetails } = this.props;
        return (
            loading
                ? <LoadingBar />
                : <Fragment>
                    <h4 className="mt-2 mb-3">Retailer</h4>
                    <InputGroup className="search-box pb-2">
                        <FormControl id="nameField" type="text" name="name" placeholder="Search by name" value={searchFilter} onChange={(e) => this.onFilterChange(e.target.value)} />
                        <InputGroup.Append>
                            <InputGroup.Text id="searchBox" className="search-icon">
                                <FontAwesomeIcon icon={faSearch} />
                            </InputGroup.Text>
                        </InputGroup.Append>
                    </InputGroup>
                    {errorMessages.length > 0 && <ErrorAlert messages={errorMessages} />}
                    <h6 className="mt-4 mb-3">Select Retailer</h6>
                    {filteredRetailers.length > 0
                        ? <Row>
                            {filteredRetailers.map(retailer => <RetailerCard key={retailer.id} retailer={retailer} onCardClick={handleRetailerSelection} selected={retailer.id === retailerDetails.id} />)}
                        </Row>
                        : <WarningAlert message="There are no stores matching your search result. Check the spelling and try again." />}
                </Fragment>
        );
    }
}

export default CreateReturnRetailerDetails;
