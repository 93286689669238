import React, { Component } from 'react';
import RRLogo from '../../common/RRLogo'
import { Form, FormGroup, Button } from 'react-bootstrap';
import FormValidator from '../../common/FormValidator';
import { POST } from '../../../Consumer';
import Endpoints from '../../common/Endpoints';
import { ErrorAlert } from '../../common/Alerts';
import { Address } from '../shared/Address';
import { isNIPostcode } from '../../../Utilities';

const POSTCODE_PATTERN = /^[a-z]{1,2}\d[a-z\d]?\s*\d[a-z]{2}$/i;

export class FirstAddress extends Component {

    constructor(props) {
        super(props);

        this.validator = new FormValidator([
            {
                field: 'name',
                method: 'isEmpty',
                validWhen: false,
                message: 'Enter your full name'
            },
            {
                field: 'line1',
                method: 'isEmpty',
                validWhen: false,
                message: 'Enter a valid address'
            },
            {
                field: 'city',
                method: 'isEmpty',
                validWhen: false,
                message: 'Enter a valid city'
            },
            {
                field: 'county',
                method: 'isEmpty',
                validWhen: false,
                message: 'Enter a valid county'
            },
            {
                field: 'postcode',
                method: 'matches',
                args: [POSTCODE_PATTERN],
                validWhen: true,
                message: 'Enter a valid postcode'
            },
            {
                field: 'addressType',
                method: 'isEmpty',
                validWhen: false,
                message: 'Select an option'
            }
        ]);

        this.state = {
            addressTypes: [],
            errorMessages: [],
            userId: '',
            addressTypeId: '',
            form: {
                name: '',
                line1: '',
                line2: '',
                city: '',
                county: '',
                postcode: '',
                notes: '',
                addressType: 'Personal',
                defaultAddress: false
            },
            validation: this.validator.valid(),
            nonNIPostcode: true
        };
    }

    validate = (form) => {
        return this.validator.validate(form);
    }

    componentDidMount() {
        Promise.all([this.getAddressTypes()]);
    }

    getAddressTypes() {
        return POST(Endpoints.ALL_ADDRESS_TYPES)
            .then(response => response.json())
            .then(result => {
                this.setState({ addressTypes: result ? result : [] });
            }).catch(() => this.setState({ errorMessages: ["There has been an issue retrieving address types."] }));
    }

    handleInputChange = (e) => {
        let { name, value, type } = e.target;

        if (type === "radio") {

            if (name === "addressType") {
                if (value === 'Personal') {
                    this.setState(prevState => ({
                        form: {
                            ...prevState.form,
                            addressType: 'Personal',
                        }
                    }));
                } else {
                    this.setState(prevState => ({
                        form: {
                            ...prevState.form,
                            addressType: 'Business'
                        }
                    }));
                }
            }

        }

        if (e.target.getAttribute('parent')) {
            let parent = e.target.getAttribute('parent');
            let parentValue = this.state.form[parent];
            parentValue[name] = value;

            name = parent;
            value = parentValue;
        }

        this.setState(prevState => ({
            ...prevState,
            form: {
                ...prevState.form,
                ...{ [name]: value }
            }
        }));
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const { form, addressTypes } = this.state;

        const validation = this.validate(form);
        this.setState({ validation: validation });
        this.submitted = true;

        let user = JSON.parse(localStorage.getItem('user'));

        const viewModel = {
            addressTypeId: addressTypes.find(f => f.type === form.addressType).id,
            address: form,
            default: true,
            active: true
        }

        if (validation.isValid) {
            if (isNIPostcode(form.postcode)) {
                this.setState({ nonNIPostcode: false });
            } else {
                this.setState({ nonNIPostcode: true });
                return POST(Endpoints.ADDRESS.POST.CREATE_ADDRESS, viewModel)
                    .then(response => response.json())
                    .then((result) => {
                        if (result.error) {
                            if (result.data) this.setState({ errorMessages: result.data });
                            else this.setState({ errorMessages: result.message });
                        }
                        else {
                            user.setupComplete = true;
                            localStorage.setItem('user', JSON.stringify(user));
                            this.props.history.push({ pathname: "/address/complete" });
                            window.location.reload();
                        }
                    }).catch(() => this.setState({ errorMessages: ["There has been an issue submitting the address."] }));
            }
        }
    }

    renderForm = () => {
        const { form, addressTypes, validation, errorMessages, nonNIPostcode } = this.state;

        return (
            <Form onSubmit={this.handleSubmit}>
                {errorMessages.length > 0 && <ErrorAlert messages={errorMessages} />}
                {!nonNIPostcode && <ErrorAlert messages="Return Robin services in Northern Ireland are currently unavailable, please contact customer service if more information is necessary." />}

                <Address
                    details={form}
                    validation={validation}
                    showDefault={false}
                    handleInputChange={this.handleInputChange}
                    addressTypes={addressTypes}
                    nonNIPostcode={nonNIPostcode}
                />

                <FormGroup>
                    <Button variant="primary" block type="submit" value="Submit">Add Address</Button>
                </FormGroup>

            </Form>
        );
    }

    render() {
        return (
            <div className="center">
                <div className="container">
                    <RRLogo />
                    <div className="center-block mb-5">
                        <h3>Add Address</h3>
                        <p>Please enter your address.</p>
                        {this.renderForm()}
                    </div>
                </div>
            </div>
        )
    }
}