// Bank account types
export const BANK_ACCOUNT_TYPES = [
    { id: "bacs_debit", name: "BACS" }
];

// Sub nav
export const PAYMENTS_SUB_NAV = [
    { text: "Card Payment", link: "/retailer/settings/payments" },
    { text: "Direct Debit", link: "/retailer/settings/payments/directdebit" }
];

export const PAYMENTS_SUB_NO_DD_NAV = [
    { text: "Card Payment", link: "/retailer/settings/payments" }
];

// Wizard
export const CREATE_RETURN_STEPS = ["Address", "Retailer", "Order Details", "Product Details", "Return Details", "Summary"];
