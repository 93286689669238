import React, { Fragment } from 'react';
import { FormGroup, FormLabel } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { formatDate } from '../../../../../Utilities';
import { ErrorAlert } from '../../../../common/Alerts';
import TermsAndConditions from '../../../../../termsandconditions.pdf';

const Address = ({ selectedAddress }) =>
    <section>
        <h4>Address</h4>
        <p className="title">Selected Address</p>
        <p>{selectedAddress}</p>
    </section>

const Retailer = ({ selectedRetailer }) =>
    <section>
        <h4>Retailer</h4>
        <p className="title">Selected Retailer</p>
        <p>{selectedRetailer}</p>
    </section>

const OrderDetails = ({ emailAddress, orderId, orderReference, orderDate }) =>
    <section>
        <h4>Order Details</h4>
        <p className="title">Email Address</p>
        <p>{emailAddress}</p>
        {orderReference
            ? <Fragment>
                <p className="title">Order Reference</p>
                <p>{orderReference}</p>
            </Fragment>
            : <Fragment>
                <p className="title">Order ID</p>
                <p>{orderId}</p>
                <p className="title">Order Date</p>
                <p>{formatDate(orderDate)}</p>
            </Fragment>
        }
    </section>

const ProductDetails = ({ products, returnReason, otherReason, otherReasonInformation, additionalInformation }) =>
    <section>
        <h4>Product Details</h4>
        {(products && products.length > 0) && <div className="mb-3">
            <p className="title">Items</p>
            {products.map(product => <p key={product.id} className="mb-0">{product.name} {product.skuCode && <span>- {product.skuCode}</span>}</p>)}
        </div>
        }
        <p className="title">Return Reason</p>
        <p>{returnReason}</p>
        {otherReason && <Fragment>
            <p className="title">Other Reason</p>
            <p>{otherReasonInformation}</p>
        </Fragment>}
        {additionalInformation && <Fragment>
            <p className="title">Additional Information</p>
            <p>{additionalInformation}</p>
        </Fragment>}
    </section>

const ReturnDetails = ({ collectionDate }) =>
    <section>
        <h4>Return Details</h4>
        <p className="title">Collection Date</p>
        <p>{formatDate(collectionDate)}</p>
    </section>

const ParcelConfirmation = ({ parcelConfirmationDetails, handleParcelConfirmationInputChange, parcelConfirmationValidation }) =>
    <section className="section-confirmation">
        <h4>Parcel Confirmation</h4>
        <p className="title">Please check your parcel and confirm:</p>
        <ul>
            <li><FontAwesomeIcon icon={faCheckCircle} className="green" />Doesn't contain prohibited items</li>
            <li><FontAwesomeIcon icon={faCheckCircle} className="green" />Weighs less than 30kg</li>
            <li><FontAwesomeIcon icon={faCheckCircle} className="green" />Doesn't go over the maximum size 120cm long / maximum dimensions .23m3</li>
        </ul>
        <FormGroup className={`custom-control custom-checkbox ${!parcelConfirmationValidation && "custom-input-error"}`}>
            <input id="terms-confirmation" className="custom-control-input" type="checkbox" checked={parcelConfirmationDetails.confirmation} onChange={handleParcelConfirmationInputChange} />
            <FormLabel className="custom-control-label" htmlFor="terms-confirmation"><span className="custom-checkbox-label">{"I confirm and agree to the "}<a href={TermsAndConditions} target='_blank' rel="noopener noreferrer" alt='Return Robin Terms and Conditions - opens in a new window'>terms & conditions</a></span></FormLabel>
        </FormGroup>
    </section>

const ReturnSummary = ({ summary, parcelConfirmationDetails, handleParcelConfirmationInputChange, parcelConfirmationValidation, showParcelSummary = true }) =>
    <Fragment>
        {(!parcelConfirmationValidation && showParcelSummary) && <ErrorAlert messages="You need to confirm your parcel complies with the restrictions and agree to the terms and conditions." />}
        <Address selectedAddress={summary.selectedAddress} />
        <Retailer selectedRetailer={summary.selectedRetailer} />
        <OrderDetails emailAddress={summary.emailAddress} orderId={summary.orderId} orderReference={summary.orderReference} orderDate={summary.orderDate} />
        <ProductDetails products={summary.products} returnReason={summary.returnReason} otherReason={summary.otherReason} otherReasonInformation={summary.returnReasonInformation} additionalInformation={summary.additionalInformation} />
        <ReturnDetails collectionDate={summary.collectionDate} />
        {showParcelSummary && <ParcelConfirmation parcelConfirmationDetails={parcelConfirmationDetails} handleParcelConfirmationInputChange={handleParcelConfirmationInputChange} parcelConfirmationValidation={parcelConfirmationValidation} />}
    </Fragment>

export default ReturnSummary;