import React, { Component, Fragment } from 'react';
import { Row, Col, Card, Image, FormGroup, FormLabel, FormControl, Button } from 'react-bootstrap';
import { GET, POST } from '../../../Consumer';
import Endpoints from '../../common/Endpoints';
import FormValidator from '../../common/FormValidator';
import SettingsNav from '../../common/nav/SettingsNav';
import LoadingBar from '../../common/LoadingBar';
import Confirm from '../../common/modals/Confirm';
import { RetailerSelectionCard, WarehouseSelectionCard } from '../../common/inputs/AddressSelectionCards';
import { ErrorAlert } from '../../common/Alerts';
import SelazarIntegrationError from './SelazarIntegrationError';

const SelazarLogo = () =>
    <Card className="card-integration-summary mb-4">
        <Image src='Images/selazar-logo.svg' />
    </Card>

const ApiKey = ({ apiKey, validation, handleInputChange }) =>
    <FormGroup>
        <FormLabel htmlFor="apiKeyField">API Key</FormLabel>
        <FormControl id="apiKeyField" className={validation.apiKey.isInvalid ? "input-error" : ""} type="text" name="apiKey" onChange={(e) => handleInputChange("apiKey", e.target.value)} value={apiKey} />
        <span className="text-danger">{validation.apiKey.message}</span>
    </FormGroup>

const Connection = ({ isConnected, handleInputChange }) =>
    <section className="mt-4">
        <h3 className="mb-3">Do you want this integration to be connected now?</h3>
        <div className="form-input-description mb-3">
            <p>You can connect and disconnect your integration at any stage. Please note until this integration is connected it will not work.</p>
        </div>
        <FormGroup>
            <FormGroup className="custom-control custom-radio mb-0">
                <FormControl className="custom-control-input" type="radio" id="radio-connect" name="connect" value={isConnected} checked={isConnected} onChange={() => handleInputChange("isConnected", !isConnected)} />
                <FormLabel className="custom-control-label font-weight-normal" htmlFor="radio-connect">Yes, connect now</FormLabel>
            </FormGroup>
            <FormGroup className="custom-control custom-radio mb-0">
                <FormControl className="custom-control-input" type="radio" id="radio-disconnect" name="disconnect" value={!isConnected} checked={!isConnected} onChange={() => handleInputChange("isConnected", !isConnected)} />
                <FormLabel className="custom-control-label font-weight-normal" htmlFor="radio-disconnect">No, do not connect now</FormLabel>
            </FormGroup>
        </FormGroup>
    </section>

const Actions = ({ handlePrimaryAction, primaryText, onCancelClick }) =>
    <FormGroup className="mt-4">
        <div className="float-right d-none d-sm-block">
            <Button variant="link" className="underline" type="button" onClick={onCancelClick}>Cancel</Button>
            <Button variant="primary" className="ml-2" onClick={handlePrimaryAction}>{primaryText}</Button>
        </div>
        <div className="d-block d-sm-none text-center">
            <Button variant="link" className="underline" type="button" onClick={onCancelClick}>Cancel</Button>
            <Button variant="primary" block onClick={handlePrimaryAction}>{primaryText}</Button>
        </div>
    </FormGroup>

const AddIntegrationDetails = ({ integration, validation, showValidApiKeyError, handleInputChange, handleNextStep, handleShowHideCancelModal }) =>
    <Fragment>
        <SelazarLogo />
        {showValidApiKeyError && <ErrorAlert messages=" Please ensure api key is valid and try again." />}
        <ApiKey apiKey={integration.apiKey} validation={validation} handleInputChange={handleInputChange} />
        <Connection isConnected={integration.isConnected} handleInputChange={handleInputChange} />
        <Actions handlePrimaryAction={handleNextStep} primaryText="Next Step" onCancelClick={handleShowHideCancelModal} />
    </Fragment>

const AddIntegrationWarehouse = ({ selectedId, retailerAddress, selazarWarehouses, handleWarehouseInputChange, handleSaveClick, handleShowHideCancelModal }) =>
    <Fragment>
        <SelazarLogo />
        <h3 className="mt-5 mb-4">Choose a Warehouse</h3>
        <Row>
            <Col sm={12} md={6} className="mb-4">
                <RetailerSelectionCard selectedId={selectedId} address={retailerAddress} handleWarehouseInputChange={handleWarehouseInputChange} />
            </Col>
            {selazarWarehouses.length && selazarWarehouses.map(warehouse =>
                <Col key={`warehouse-${warehouse.id}`} sm={12} md={6} className="mb-4">
                    <WarehouseSelectionCard selectedId={selectedId} warehouse={warehouse} handleWarehouseInputChange={handleWarehouseInputChange} />
                </Col>
            )}
        </Row>
        <Actions handlePrimaryAction={handleSaveClick} primaryText="Finish" onCancelClick={handleShowHideCancelModal} />
    </Fragment>

class AddSelazarIntegration extends Component {

    validator = new FormValidator([
        {
            field: 'apiKey',
            method: 'isEmpty',
            validWhen: false,
            message: 'Please enter your API key'
        },
    ]);

    state = {
        loading: true,
        integration: {
            apiKey: "",
            isConnected: false,
            warehouseID: "",
            useSelazarWarehouse: false
        },
        retailerAddress: {},
        selazarWarehouses: [],
        validation: this.validator.valid(),
        saveError: false,
        showWarehouseSelection: false,
        showCancelModal: false,
        showValidApiKeyError: false,
        hasValidIntegration: false
    };

    async componentDidMount() {
        await this.retailerIntegrationValid();
        this.setState({ loading: false });
    }

    retailerIntegrationValid = () => {
        const retailerId = JSON.parse(localStorage.getItem('user'))?.retailer?.id;
        return GET(new URL(retailerId, Endpoints.SETTINGS.GET.VALID_SELAZAR_INTEGRATION))
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(result => {
                const hasValidIntegration = result ? result.data : false;
                this.setState({ hasValidIntegration: hasValidIntegration });
            })
            .catch(error => console.log(error));
    }

    handleNextStep = async () => {
        const { integration } = this.state;
        this.setState({ loading: true });
        const validation = this.validator.validate(integration);

        if (validation.isValid) {
            const { integration } = this.state
            return GET(Endpoints.SETTINGS.GET.VALID + integration.apiKey)
                .catch(error => console.log(error))
                .then(response => {
                    if (response.ok) return response.json();
                })
                .then(results => {
                    const valid = results ? results.data : false;
                    if (valid) {
                        this.setState({ validation: validation, showValidApiKeyError: false });
                        (async () => {
                            await Promise.all([
                                this.getReturnRetailer(),
                                this.getSelazarWarehouses()
                            ])
                            this.setState({ showWarehouseSelection: true, loading: false });
                        })();
                    } else this.setState({ showValidApiKeyError: true, showWarehouseSelection: false, validation: validation, loading: false });
                })

        } else this.setState({ validation: validation, loading: false });
    }

    getReturnRetailer = () =>
        GET(Endpoints.SETTINGS.GET.RETAILER_ADDRESS)
            .catch(error => console.log(error))
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(results => {
                const retailerAddress = results ? results.data[0] : {};
                const retailerAddressId = results ? results.data[0].id : "";
                this.setState(prevState => ({ retailerAddress: retailerAddress, integration: { ...prevState.integration, warehouseID: retailerAddressId } }));
            });

    getSelazarWarehouses = () => {
        const { integration } = this.state;

        return GET(Endpoints.SETTINGS.GET.SELAZAR_WAREHOUSES + integration.apiKey)
            .catch(error => console.log(error))
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(results => {
                const selazarWarehouses = results ? results.data : [];
                this.setState({ selazarWarehouses: selazarWarehouses });
            });
    }

    handleInputChange = (name, value) => this.setState(prevState => ({ integration: { ...prevState.integration, [name]: value } }));
    handleWarehouseInputChange = (useSelazarWarehouse, id) => this.setState(prevState => ({ integration: { ...prevState.integration, useSelazarWarehouse: useSelazarWarehouse, warehouseID: id } }));
    handleShowWarehouseSelection = () => this.setState({ showWarehouseSelection: true });
    handleShowHideCancelModal = () => this.setState(prevState => ({ showCancelModal: !prevState.showCancelModal }));
    handleCancelIntegration = () => this.props.history.push('/retailer/settings/integrations');

    handleSaveClick = () => {
        const { integration } = this.state;
        this.setState({ loading: true });

        return POST(Endpoints.SETTINGS.POST.INTEGRATION_SELAZAR, integration)
            .catch(error => console.log(error))
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(result => {
                if (result && result.data) {
                    this.props.history.push({ pathname: "/retailer/settings/integrations", state: { showSuccess: true } });
                } else this.setState({ saveError: true, loading: false });
            });
    }

    render() {

        const { loading, integration, retailerAddress, selazarWarehouses, validation, showWarehouseSelection, showCancelModal, showValidApiKeyError, saveError, hasValidIntegration } = this.state;

        return (
            loading
                ? <LoadingBar />
                : <Fragment>
                    <SettingsNav activeTitle="Integrations" />
                    <div className="nav-content">
                        <div className="main-content">
                            <Row>
                                <Col sm={12} md={6}>
                                    <h3 className="mb-5">Integrations</h3>
                                    {hasValidIntegration
                                        ? <SelazarIntegrationError history={this.props.history} />
                                        : <Fragment>
                                            {saveError && <ErrorAlert messages="Unable to save integration, please try again." />}
                                            {showWarehouseSelection
                                                ? <AddIntegrationWarehouse
                                                    selectedId={integration.warehouseID}
                                                    retailerAddress={retailerAddress}
                                                    selazarWarehouses={selazarWarehouses}
                                                    handleWarehouseInputChange={this.handleWarehouseInputChange}
                                                    handleSaveClick={this.handleSaveClick}
                                                    handleShowHideCancelModal={this.handleShowHideCancelModal}
                                                />
                                                : <AddIntegrationDetails
                                                    integration={integration}
                                                    validation={validation}
                                                    showValidApiKeyError={showValidApiKeyError}
                                                    handleInputChange={this.handleInputChange}
                                                    handleNextStep={this.handleNextStep}
                                                    handleShowHideCancelModal={this.handleShowHideCancelModal}
                                                />
                                            }
                                        </Fragment>
                                    }
                                </Col>
                            </Row>
                            <Confirm
                                title="Cancel Integration"
                                variant="outline-danger"
                                text="Are you sure you want to cancel this integration? Changes will not be saved and action cannot be undone."
                                handleClose={this.handleShowHideCancelModal}
                                handleConfirmAction={this.handleCancelIntegration}
                                buttonText="Cancel Integration"
                                linkText="Continue Editing"
                                closeLink={true}
                                show={showCancelModal}
                            />
                        </div>
                    </div>
                </Fragment>
        )
    }
}

export default AddSelazarIntegration;